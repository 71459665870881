import React, { Component } from 'react';
import Link from "react-router-dom/es/Link";
import HeaderNavClient from "./funcs/HeaderNavClient";

class Header extends Component {

	constructor(props) {
		super(props);

		this.state = {
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en'
		};
	}

	render() {

		let lng = this.state.lang;

		return (
			<header className="header">
				<div className="header-logo-area">
					<Link className="navbar-brand" to={'/' + lng + '/'}>
						<img src="/assets/img/logo.png" alt="Logo"/>
					</Link>
				</div>
				<div className="header-cu-rates">&nbsp;</div>
				<div className="header-client-nav">
					<HeaderNavClient/>
				</div>
			</header>
		);
	}
}

export default Header;
