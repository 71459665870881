import React, { Component } from 'react';
import Link from "react-router-dom/es/Link";
import PairsAside from "./PairsAside";
import PairsFloating from "./PairsFloating";

/**
 * Aside block
 *
 */
class AsideBlock extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en',
		};
	}

	render() {

		let lng = this.state.lang;

		if(this.props.isFloating) {

			return (
				<aside className={this.props.class}>
					<div className="aside-arrow"><span>&#9650;</span></div>
					<div className="aside-logo">
						<Link className="navbar-brand" to={'/' + lng + '/'}>
							<img src="/assets/img/logo.png" alt="Logo" />
						</Link>
					</div>
					<div className="aside-exchange">
						<PairsFloating />
					</div>
				</aside>
			);
		}

		return (
			<aside className={this.props.class}>
				<div className="aside-arrow"><span>&#9650;</span></div>
				<div className="aside-logo">
					<Link className="navbar-brand" to={'/' + lng + '/'}>
						<img src="/assets/img/logo.png" alt="Logo" />
					</Link>
				</div>
				<div className="aside-exchange">
					<PairsAside />
				</div>
			</aside>
		);
	}
}

export default AsideBlock;
