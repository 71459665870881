import {mainConfig} from "../configs/main";

export function inArray(item, arr) {

	if (typeof arr === "object") {
		return arr.indexOf(item) !== -1;
	} else {
		return false;
	}
}

export function arrayRemove(what, arr) {

	let idx = arr.indexOf(what);
	if (idx !== -1) {
		arr.splice(idx, 1);
	}
	return arr;
}

/*
export function noExponents(value) {

	let data= String(value).split(/[eE]/);
	if(data.length === 1) return data[0];

	let z= '', sign= this < 0 ? '-' : '', str = data[0].replace('.', ''), mag = Number(data[1]) + 1;
	if(mag < 0){
		z= sign + '0.';
		while(mag++) z += '0';
		return z + str.replace(/^-/,'');
	}
	mag -= str.length;
	while(mag--) z += '0';
	return str + z;
}
*/

export function replaceAll(content, search, replacement) {

	if (typeof content === "undefined") {
		console.log("Failed to ReplaceAll~");
		console.log(content);
		console.log(search);
		console.log(replacement);
	} else if (typeof content === "number") {
		let tmpContent = content + "";
		return tmpContent.replace(new RegExp(this.escapeRegExp(search), 'g'), replacement);
	} else {
		return content.replace(new RegExp(this.escapeRegExp(search), 'g'), replacement);
	}
}

export function escapeRegExp(str) {
	return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

export function roundCurrencyVal(amount, decimals) {
	amount = amount || 0;
	decimals = decimals ||  mainConfig.decimals;

	let result = parseFloat(amount.toFixed(decimals).replace(new RegExp("\\.0{" + decimals + "}"), ""));
	if(isFinite(result) === false) result = 0;

	return result;
}

