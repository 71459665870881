import React, {Component} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import FinexAuth from "../lib/FinexAuth";
import {mainConfig} from "../configs/main";

class AccountSecurity extends Component {

	constructor(props) {
		super(props);

		this.state = {
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en',
			selTab: 'security',
			isLoading: false,
			formReady: false,
			errors: '',
			success: '',
			passwordCuValid: 0,
			password1Valid: 0,
			password2Valid: 0,
			oldpass: '',
			newpass1: '',
			newpass2: '',
			has2fa: false,
			start2fa: false,
			googleGAvalid: 0,
			gaqrcode: '/assets/ic/qr-code-default.png',
			gacode: ''
		};
	}

	componentWillMount() {

		const userProfile = FinexAuth.getProfile();
		this.setState({ has2fa: (userProfile.s2a === 1) });

	}

	/**
	 * Click on navigation tab buttons
	 * @param e
	 */
	handleTabClicked = (e) => {

		let selValue = e.target.value;

		this.setState({
			selTab: selValue
		});

		console.log(selValue);
	};

	/**
	 * Handle entering new passwords
	 * @param event
	 */
	handlePassChange = event => {

		this.setState({[event.target.id]: event.target.value}, () => {
			this.validatePasswordsForm(true);
		});
	};

	/**
	 * Validate entered passwords
	 *
	 * @param doDisplay
	 * @returns {boolean}
	 */
	validatePasswordsForm(doDisplay) {

		let valid = true;
		let errors = '';
		this.setState({errors: '', success: '', passwordCuValid: 1, password1Valid: 1, password2Valid: 1, formReady: true});

		if (this.state.oldpass.length === 0) {
			this.setState({formReady: false, passwordCuValid: 0});
		} else {
			if (this.state.oldpass.length < 6) {
				errors = 'Current password too short!';
				this.setState({formReady: false, passwordCuValid: 2});
			}
		}

		if (this.state.newpass1.length === 0) {
			this.setState({formReady: false, password1Valid: 0});
		} else {
			if (this.state.newpass1.length < 6) {
				errors = 'New password too short!';
				this.setState({formReady: false, password1Valid: 2});
			}
		}

		if (this.state.newpass2.length === 0) {
			this.setState({formReady: false, password2Valid: 0});
		} else {
			if (this.state.newpass2.length < 6) {
				errors = 'Second password is too short!';
				this.setState({formReady: false, password2Valid: 2});
			}
			if (this.state.newpass1 !== this.state.newpass2) {
				errors = 'Passwords are not the same!';
				this.setState({formReady: false, password2Valid: 2});
			}
		}

		if (doDisplay) this.setState({errors: errors});

		return valid;
	}

	/**
	 * Submit new passwords to API
	 * @param event
	 */
	handlePassChangeSubmit = event => {

		event.preventDefault();

		let lng = this.state.lang;
		let isValid = this.validatePasswordsForm(true);

		if(isValid) {

			const userProfile = FinexAuth.getProfile();
			const authToken = userProfile.autk;

			this.setState({isLoading: true});

			fetch(mainConfig.api.setnewpass,{
				method: "POST",
				body: JSON.stringify({
					lng: lng,
					uid: userProfile.uid,
					autk: authToken,
					oldpass: this.state.oldpass,
					newpass: this.state.newpass1
				}),
				headers: { 'Accept': 'application/json' },
			}).then(response => {
				response.json().then( data => {

					if(typeof data.error === "undefined") {

						let error = 'Something went wrong, please try again!';
						this.setState({formReady: false, isLoading: false, errors: error});

					} else {
						if(data.error > 0) {
							if(data.error === 2830){
								FinexAuth.signOut();
								this.props.history.push('/' + lng + '/');
							}else {
								this.setState({formReady: false, isLoading: false, errors: data.message});
							}
						}else{
							this.setState({isLoading: false, success: 'Password succesfully changed!'});
						}
					}
				})
			});
		}
	};

	/**
	 * First step to enable Google Authenticator
	 *
	 * @param e
	 */
	handleGAenable = (e) => {
		e.preventDefault();

		const userProfile = FinexAuth.getProfile();

		fetch(mainConfig.api.getsgapicture, {
			method: "POST",
			body: JSON.stringify({
				lng: this.state.lang,
				uid: userProfile.uid,
				autk: userProfile.autk,
			}),
			headers: {
				'Accept': 'application/json'
			},
		}).then(response => {
			response.json().then(jsondata => {

				if (typeof jsondata === "undefined") {
					this.setState({errors: 'Something is wrong with the data sent from the server [1] !'});
				} else if (jsondata.error !== 0) {
					this.setState({errors: jsondata.message});
				} else {
					if (typeof jsondata.data === "object") {
						this.setState({start2fa: true, gaqrcode: jsondata.data.imgsrc});
					}
				}
			})
		})
	};

	/**
	 * Google Authenticator code handler
	 * @param e
	 */
	handleGAChange = (e) => {

		let gaNewValue = e.target.value.toUpperCase();
		gaNewValue = gaNewValue.replace(/[^-0-9]/,'');

		this.setState({gacode: gaNewValue}, () => {
			this.validateGAForm(true);
		});
	};

	/**
	 *
	 * @param doDisplay
	 * @returns {boolean}
	 */
	validateGAForm(doDisplay) {

		let valid = true;
		let errors = '';
		this.setState({errors: '', success: '', googleGAvalid: 1, formReady: true});

		if (this.state.gacode.length === 0) {
			this.setState({formReady: false, googleGAvalid: 0});
		} else {
			if (this.state.gacode.length < 6) {
				errors = 'Google Code is too short!';
				this.setState({formReady: false, googleGAvalid: 2});
			}
		}

		if (doDisplay) this.setState({errors: errors});

		return valid;
	}

	/**
	 * Activate Google Authenticator
	 * @param e
	 */
	handleGASubmit = (e) => {
		e.preventDefault();

		let lng = this.state.lang;
		let isValid = this.validateGAForm(true);

		if(isValid) {

			const userProfile = FinexAuth.getProfile();

			this.setState({isLoading: true});

			fetch(mainConfig.api.gaactivate,{
				method: "POST",
				body: JSON.stringify({
					lng: lng,
					uid: userProfile.uid,
					autk: userProfile.autk,
					gacode: this.state.gacode,
				}),
				headers: { 'Accept': 'application/json' },
			}).then(response => {
				response.json().then( data => {

					if(typeof data.error === "undefined") {

						let error = 'Something went wrong, please try again!';
						this.setState({formReady: false, isLoading: false, errors: error});

					} else {
						if(data.error > 0) {
							if(data.error === 2830){
								FinexAuth.signOut();
								this.props.history.push('/' + lng + '/');
							}else {
								this.setState({formReady: false, isLoading: false, errors: data.message});
							}
						}else{
							userProfile.s2a = 1;
							FinexAuth.saveProfile(userProfile);
							this.setState({isLoading: false, has2fa: true, success: 'Google authentication successfully activated!'});
						}
					}
				})
			});
		}
	};

	/**
	 * Disable Google Authenticator
	 * @param e
	 */
	handleGAdisable = (e) => {
		e.preventDefault();

		const userProfile = FinexAuth.getProfile();

		fetch(mainConfig.api.gadeactivate, {
			method: "POST",
			body: JSON.stringify({
				lng: this.state.lang,
				uid: userProfile.uid,
				autk: userProfile.autk,
			}),
			headers: {
				'Accept': 'application/json'
			},
		}).then(response => {
			response.json().then(jsondata => {

				if (typeof jsondata === "undefined") {
					this.setState({errors: 'Something is wrong with the data sent from the server [1] !'});
				} else if (jsondata.error !== 0) {
					this.setState({errors: jsondata.message});
				} else {
					userProfile.s2a = 0;
					FinexAuth.saveProfile(userProfile);
					this.setState({start2fa: false, has2fa: false, success: 'Google authentication deactivated!'});
				}
			})
		})
	};


	/**
	 * DISPLAY/RENDER RESULT
	 *
	 * @returns {*}
	 */
	render() {

		return (
			<div className="page account-security">
				<section className="wrapper">
					<Header {...this.props}/>
					<h2 className="page-title">Account security</h2>
					<div className="content">
						<div className="blk-tabbed-big">
							<div className="blk-tabbed-big-tabs">
								<button onClick={this.handleTabClicked} name="bt-tab" value="security" className={(this.state.selTab === 'security') ? 'active' : ''}>SECURITY</button>
								<button onClick={this.handleTabClicked} name="bt-tab" value="apiaccess" className={(this.state.selTab === 'apiaccess') ? 'active' : ''}>API ACCESSS</button>
							</div>
							<div className="blk-tabbed-big-content">
								<div className={(this.state.success.length > 0) ? 'form-err has-success' : ((this.state.errors.length > 0) ? 'form-err has-errors' : 'form-err')}>
									{this.state.errors + '' + this.state.success }
								</div>
								<div id="security" className={(this.state.selTab === 'security') ? 'blk-tabbed-big-content-slide active' : 'blk-tabbed-big-content-slide hidden'} >
									<div className="blk-tabbed-big-inside-left set-new-pass-area">
										<h5 className="inner-tab-title">Change password</h5>
										<form method="post" onSubmit={this.handlePassChangeSubmit} autoComplete="off">
											<input type="password" name="oldpass" id="oldpass" required="required"
											       placeholder="Your current password" autoComplete="off"
											       className={(this.state.password2Valid === 0) ? 'inp' : ((this.state.password2Valid === 1) ? 'inp valid' : 'inp invalid')}
											       onChange={this.handlePassChange} onBlur={this.handlePassChange}
											       onKeyUp={this.handlePassChange} value={this.state.oldpass}/>
											<input type="password" name="newpass1" id="newpass1" required="required"
											       placeholder="New password" autoComplete="off"
											       className={(this.state.password2Valid === 0) ? 'inp' : ((this.state.password2Valid === 1) ? 'inp valid' : 'inp invalid')}
											       onChange={this.handlePassChange} onBlur={this.handlePassChange}
											       onKeyUp={this.handlePassChange} value={this.state.newpass1} />
											<input type="password" name="newpass2" id="newpass2" autoComplete="off"
											       placeholder="New password" required="required"
											       className={(this.state.password2Valid === 0) ? 'inp' : ((this.state.password2Valid === 1) ? 'inp valid' : 'inp invalid')}
											       onChange={this.handlePassChange} onBlur={this.handlePassChange}
											       onKeyUp={this.handlePassChange} value={this.state.newpass2} />
											{(this.state.isLoading === true) ?
												<button type="button" className="sp-form-button"
												        disabled="disabled"><img src="/assets/ic/loading.gif" width={12}
												                                 alt="Loading" /></button> :
												<button type="submit" name="action" className="sp-form-button"
												        value="chancepassnow">SET NEW PASSWORD</button>
											}
										</form>
									</div>
									<div className="blk-tabbed-big-inside-right">
										<h5 className="inner-tab-title">Google Authenticator</h5>
										{(this.state.has2fa) ?
										<div className="sec-google-auth-disable">
											<img src="/assets/ic/google-authent-logo.png" alt="Google authenticator" width={84}/>
											<div className="sec-ga-pre-text">
												<span>&#42;</span>Disabling this feature will make your account insecure!
											</div>
											<button name="action" className="sp-form-button" onClick={this.handleGAdisable}>DISABLE GOOGLE AUTHENTICATOR</button>

										</div> : (this.state.start2fa) ?
											<div className="sec-google-auth-enable">
												<div className="sec-google-auth-enable-line-1">
													<div className="sec-google-auth-enable-line-1-left">
														<small>1. Scan this QR code:</small>
														<img src={this.state.gaqrcode} alt="QR code" width="112"/>
													</div>
													<div className="sec-google-auth-enable-line-1-right">
														<small>...or enter this Google Auth secret code into your Google Authenticator device:</small>
													</div>
												</div>
												<div className="sec-google-auth-enable-line-2">
													<small>2. Enter 6-digit passcode:</small>
													<form method="post" autoComplete="off" onSubmit={this.handleGASubmit} >
														<input type="text" maxLength={6} required="required" placeholder="XXXXXX"
														       autoComplete="off"
														       className={(this.state.googleGAvalid === 0) ? 'inp' : ((this.state.googleGAvalid === 1) ? 'inp valid' : 'inp invalid')}
														       onChange={this.handleGAChange} onBlur={this.handleGAChange}
														       onKeyUp={this.handleGAChange} value={this.state.gacode}/>
														{(this.state.isLoading === true) ?
															<button type="button" className="sp-form-button"
															        disabled="disabled"><img src="/assets/ic/loading.gif" width={12}
															                                 alt="Loading" /></button> :
															<button type="submit" name="action" className="sp-form-button"
															        value="changeganow">ACTIVATE GOOGLE AUTHENTICATOR</button>
														}
													</form>
												</div>
											</div>:
											<div className="sec-google-auth-pre-enable">
												<div className="sec-google-auth-pre-enable-line-1">
													<img src="/assets/ic/google-authent-logo.png" alt="Google authenticator" width={84}/>
													<div className="sec-ga-pre-text">
														<span>&#42;</span>
														Important operations will be confirmed from your mobile device!
													</div>
													<button name="action" className="sp-form-button" onClick={this.handleGAenable}>CONFIGURE NOW</button>
												</div>
											</div>
										}
									</div>
								</div>
								<div id="apiaccess" className={(this.state.selTab === 'apiaccess') ? 'blk-tabbed-big-content-slide active' : 'blk-tabbed-big-content-slide hidden'} >

								</div>
							</div>
						</div>
					</div>
					<Footer {...this.props}/>
				</section>
			</div>
		);
	}
}

export default AccountSecurity;