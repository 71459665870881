import React, {Component} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import PairsTableLarge from "../components/PairsTableLarge";
import {Link} from "react-router-dom";
import {FormattedMessage} from 'react-intl';
import FinexAuth from "../lib/FinexAuth";


class Home extends Component {

	constructor(props) {
		super(props);

		this.state = {
			theme: 0,
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en',
		}
	}

	render() {

		let isAuth = FinexAuth.isAuthenticated();

		let lng = this.state.lang;

		return (
			<div className="page page-home">
				<section className="wrapper">
					<Header {...this.props}/>
					<h1 className="page-title"><FormattedMessage id="home.title" defaultMessage="Trade Limitless"/></h1>
					<h3 className="page-title"><FormattedMessage id="home.subtitle" defaultMessage="Exchange CryptoCoins with order types you dreamed of."/></h3>
					<div className="content hp-wrapper">
						{(isAuth === false) ?
							<div className="hp-buttons">
							<Link to={'/' + lng + '/register/'} className="btn-big btn-green"><FormattedMessage id="home.register" defaultMessage="OPEN ACCOUNT"/></Link>
							<Link to={'/' + lng + '/login/'} className="btn-big"><FormattedMessage id="home.login" defaultMessage="LOGIN"/></Link>
							</div>
							:
							''
						}
						<div className="hp-coin-list-hl">
							<PairsTableLarge className="markets-large" />
						</div>
					</div>
					<Footer {...this.props}/>
				</section>
			</div>
		);
	}
}

export default Home;
