import React, { Component } from 'react';

class FinexError extends Component {

	constructor(props) {
		super(props);
		this.state = { error: false };
	}

	componentDidCatch(error, info) {
		this.setState({ error, info });
	}

	render() {
		if (this.state.error) {
			return <h1 className="system-error">System Error, please report to the support: <br/><small><em> {this.state.error.toString()}</em></small></h1>;
		}
		return this.props.children;
	}
}

export default FinexError;
