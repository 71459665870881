import React, {Component} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {mainConfig} from "../configs/main";
import {Link} from "react-router-dom";
import FinexAuth from "../lib/FinexAuth";

class Wallets extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			searchValue: '',
			errors: '',
			walletsPureData: [],
			wallets: [],
			sortItem: null,
			sortDir: null
		};
	}

	componentWillMount() {

		const userProfile = FinexAuth.getProfile();

		const userData = {
			uid: userProfile.uid,
			autk: userProfile.autk
		};

		fetch(mainConfig.api.wallets, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				'Accept': 'application/json'
			},
		}).then(response => {

			response.json().then(jsondata => {

				if (typeof jsondata === "undefined") {
					let error = 'Something is wrong with the data sent from the server [1] !';
					this.setState({errors: error});
				} else if (jsondata.error !== 0) {
					let error = jsondata.message;
					this.setState({errors: error});
				} else {

					let finalWallets = [];

					if (typeof jsondata.wallets === "object") {

						jsondata.wallets.forEach(function (item) {
							finalWallets.push(item)
						});

						this.setState({walletsPureData: finalWallets}, () => {
							this.redrawData();
						})
					}
				}
			})
		})
	}

	redrawData() {

		let filteredWallets = [];

		const searchValue = this.state.searchValue;

		if (searchValue.length < 1 && this.state.sortItem === null) {
			filteredWallets = this.state.walletsPureData;
		}else {

			if (searchValue.length > 0) {
				this.state.walletsPureData.forEach(function (fee) {

					let regSearchVal = new RegExp(searchValue, "ig");
					let foundSymbol = fee.symbol.match(regSearchVal);
					let foundName = fee.coinname.match(regSearchVal);
					let foundAddr = fee.address.match(regSearchVal);

					if (foundSymbol || foundName || foundAddr) {
						filteredWallets.push(fee);
					}
				});
			}else{
				filteredWallets = this.state.walletsPureData;
			}

			if(this.state.sortItem !== null) {

				const filterDirection = this.state.sortDir;

				if(this.state.sortItem === 'symbol') {
					filteredWallets.sort(function(feeA, feeB){
						if(filterDirection != null) {
							if(filterDirection === 'ASC') {
								if (feeA.symbol < feeB.symbol) return -1;
								if (feeA.symbol > feeB.symbol) return 1;
							}else{
								if (feeA.symbol < feeB.symbol) return 1;
								if (feeA.symbol > feeB.symbol) return -1;
							}
						}
						return 0;
					})
				}
			}
		}
		this.setState({wallets: filteredWallets})
	}

	searchChange = e => {

		let searchValue = e.target.value;
		searchValue = searchValue.replace(/[^a-zA-Z0-9]+/g, "");

		this.setState({searchValue: searchValue}, () => {
			this.redrawData();
		});
	};

	sortChange = e => {

		let sortBy = e.target.getAttribute("data-sortby");
		let sortDir = this.state.sortDir;
		if (sortDir === null) {
			sortDir = 'ASC';
		} else {
			sortDir = (this.state.sortDir === 'ASC') ? 'DESC' : 'ASC';
		}
		this.setState({sortItem: sortBy, sortDir: sortDir}, () => {
			this.redrawData();
		});
	};


	render() {

		let feeRows = [];
		if (this.state.wallets.length > 0) {
			feeRows = this.state.wallets.map((item) =>
				<tr key={item.symbol}>
					<td><img src={mainConfig.api.icos + item.ico + ".png"} alt="" className="coin-ico"/><strong>{item.symbol}</strong></td>
					<td><span>{item.coinname}</span></td>
					<td>{item.label}</td>
					<td>{item.address}</td>
					<td>{item.createdon}</td>
				</tr>
			);
		} else {
			feeRows = (
				<tr key="not-found">
					<td colSpan={3} style={{textAlign: "center"}}>
						<em>No records found!</em>
					</td>
				</tr>
			);
		}

		return (
			<div className="page page-wallets">
				<section className="wrapper">
					<Header {...this.props}/>

					<h2 className="page-title">My Wallets</h2>

					<div className="content">

						<div className="fin-table-hl">
							<div className="fin-table">
								<div className="fin-table-head">
									<div className="fin-table-head-left">
										<p><Link to="/addwallet" className="cons-apply-lnk">Add wallet</Link></p>
									</div>
									<div className="fin-table-head-right">
										<input type="text" name="filter" value={this.state.searchValue}
										       className="search"
										       placeholder="Search" onChange={this.searchChange}
										       onKeyUp={this.searchChange}/>
									</div>
								</div>
								<div className="fin-table-body">
									<table>
										<thead>
										<tr>
											<th>
												<button type="button" data-sortby="symbol" onClick={this.sortChange}
												        className={
													        (this.state.sortDir != null) ?
														        'fin-order-btn ' + this.state.sortDir.toLowerCase() :
														        'fin-order-btn'
												        }>Symbol
												</button>
											</th>
											<th>
												<button type="button" data-sortby="name" onClick={this.sortChange}
												        className={
													        (this.state.sortDir != null) ?
														        'fin-order-btn ' + this.state.sortDir.toLowerCase() :
														        'fin-order-btn'
												        }>Coin name
												</button>
											</th>
											<th>
												<button type="button" data-sortby="label" onClick={this.sortChange}
											            className={
												            (this.state.sortDir != null) ?
													            'fin-order-btn ' + this.state.sortDir.toLowerCase() :
													            'fin-order-btn'
											            }>Label
												</button>
											</th>
											<th>
												<button type="button" data-sortby="address" onClick={this.sortChange}
											            className={
												            (this.state.sortDir != null) ?
													            'fin-order-btn ' + this.state.sortDir.toLowerCase() :
													            'fin-order-btn'
											            }>Address
												</button>
											</th>
											<th>
												<button type="button" data-sortby="createdon" onClick={this.sortChange}
											            className={
												            (this.state.sortDir != null) ?
													            'fin-order-btn ' + this.state.sortDir.toLowerCase() :
													            'fin-order-btn'
											            }>Added on
												</button>
											</th>
										</tr>
										</thead>
										<tbody>
										{feeRows}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<Footer {...this.props}/>
				</section>
			</div>
		);
	}
}

export default Wallets;