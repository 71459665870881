import React, {Component} from 'react';
import Griddle, {plugins, RowDefinition, ColumnDefinition} from 'griddle-react';
import {mainConfig} from "../configs/main";

class Filter extends Component {

	constructor(props) {
		super(props);

		let filterButtons = mainConfig.exchangeFilterItems;
		if(filterButtons[0] === "FAV") filterButtons.shift();

		this.state = {
			btnFilterVal: "",
			searchValue : "",
			filterButtons: filterButtons
		};
	}

	btnClick = e => {

		this.setState({searchValue: ""});

		let savedValue = this.state.btnFilterVal;

		if(savedValue !== e.target.value){
			this.setState({btnFilterVal: e.target.value});
			this.props.setFilter(e.target.value);
		}else{
			this.setState({btnFilterVal: ""});
			this.props.setFilter("");
		}
	};

	searchChange = e => {

		this.setState({searchValue: e.target.value});

		this.props.setFilter(e.target.value);
		let elms = document.getElementsByClassName("mfm-btn");
		for(let i = 0; i < elms.length; i++){
			elms[i].className="mfm-btn";
		}

	};

	render() {

		const listButtons = this.state.filterButtons.map((symbol) =>
			<button className={(this.state.btnFilterVal === symbol) ? "mfm-btn active" : "mfm-btn"}
			        key={symbol} value={symbol} onClick={this.btnClick}>{symbol}</button>);

		return (
			<div className="markets-filters-all">
				<div className="markets-filter-main-markets">
					{listButtons}
				</div>
				<div className="markets-filter-main-search">
					<input type="text" id="marketsfilter" name="filter" value={this.state.searchValue}
					       placeholder="Search" onChange={this.searchChange} onKeyUp={this.searchChange}/>
				</div>
			</div>
		);
	}
}

class PairsTableLarge extends Component {

	pair1;
	pair2;
	pairs = [];

	constructor(props, context) {
		super(props, context);

		this.state = {
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en',
			pairs: null
		};
	}

	componentDidMount() {

		fetch(mainConfig.api.pairs)
			.then(response => response.json())
			.then(jsondata => {

				if (typeof jsondata === "undefined") {

					let error = 'Something is wrong with the data sent from the server [1] !';
					this.setState({errors: error});

				} else if (Number(jsondata.error) !== 0) {

					let error = jsondata.message;
					this.setState({errors: error});

				} else {

					let finalPairs = [];

					if(typeof jsondata.pairs === "object") {
						jsondata.pairs.forEach(function (pair) {
							let comp = {
								id: pair.id,
								label: {p1: pair.pair1, p2: pair.pair2, ico: pair.ico},
								coinname: pair.coinname,
								price: {value: pair.price, change: pair.change},
								low: pair.low,
								high: pair.high,
								vol: pair.vol,
							};
							finalPairs.push(comp)
						});

						this.setState({pairs: finalPairs});
					}
				}
			})
			.catch(err => console.error(this.props.url, err.toString()))
	}

	render() {

		let lng = this.state.lang;

		const SymbolsColumn = ({value}) => {

			let itemPair1 = "", itemPair2 = "", itemIco = "" ;
			let entr = Array.from(value.entries());

			entr.forEach(function (items) {

				if (items[0] === 'p1') {
					itemPair1 = items[1];
				} else if (items[0] === 'p2') {
					itemPair2 = items[1];
				} else if (items[0] === 'ico') {
					itemIco = mainConfig.api.icos + items[1] + '.png';
				}

			});
			return <span className="exch-nm-ic"><img src={itemIco} width={16} alt={itemPair1} /><strong>{itemPair1}</strong>/{itemPair2}</span>;
		};

		const PriceChangeColumn = ({value}) => {

			let itemValue = "", itemChange = 0, changeClass = "nochange";
			let entr = Array.from(value.entries());

			entr.forEach(function (items) {
				if (items[0] === 'value') {
					itemValue = items[1];
				} else if (items[0] === 'change') {
					itemChange = parseFloat(items[1]);
				}
			});

			if (itemChange != null) {
				if (itemChange > 0.000000000) {
					changeClass = "up";
					itemChange = "+" + itemChange.toFixed(2);
				} else {
					changeClass = "down";
					itemChange = "-" + Math.abs(itemChange).toFixed(2);
				}

			}

			return <span className="cu-price">{itemValue} <em className={changeClass}>{itemChange}&#37;</em></span>;
		};

		const NewLayout = ({Table, Filter}) => (
			<div className={this.props.className}>
				<div className="markets-filters-hl">
					<Filter/>
				</div>
				<div className="markets-filter-main-table">
					<Table/>
				</div>
			</div>
		);

		const pageProperties={
			showPager: false,
			currentPage: 1,
			pageSize: 2000,
		};

		return (
			<Griddle data={this.state.pairs} plugins={[plugins.LocalPlugin]} ref="Griddle" pageProperties={pageProperties}
			         components={{
				         Layout: NewLayout,
				         Filter,
				         RowEnhancer: OriginalComponent => props => (
					         <OriginalComponent
						         {...props}
						         onClick={() => {
							         let item = this.state.pairs[props.griddleKey];
							         window.location.href = '/' + lng + '/pair/' + encodeURI(item.label.p1 + '-' + item.label.p2);
						         }}
					         />
				         )
			         }} >
				<RowDefinition>
					<ColumnDefinition id="label" title="Pair" order={1} customComponent={SymbolsColumn}/>
					<ColumnDefinition id="coinname" title="Coin" order={2} />
					<ColumnDefinition id="price" title="Current price" order={3} customComponent={PriceChangeColumn}/>
					<ColumnDefinition id="high" title="High 24 h" order={4} />
					<ColumnDefinition id="low" title="Low 24 h" order={5} />
					<ColumnDefinition id="vol" title="Volume" order={6} />
				</RowDefinition>
			</Griddle>
		);
	}
}

export default PairsTableLarge;

