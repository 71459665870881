import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import FinexAuth from '../../lib/FinexAuth';
import {mainConfig} from "../../configs/main";

class HeaderNavClient extends Component {

	constructor(props) {
		super(props);

		this.state = {
			userMenuState: 0,
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en'
		};
	}

	toggleUseMenu(menuState) {
		this.setState({userMenuState: menuState});
	};

	logIn = () => {
		let lng = this.state.lang;
		this.props.history.push('/' + lng + '/login')
	};

	signOut = () => {

		let lng = this.state.lang;
		let profile = FinexAuth.getProfile();

		fetch(mainConfig.api.auth,{
			method: "POST",
			body: JSON.stringify( {uid: profile.uid, autk: profile.autk} ),
			headers: {
				'Accept': 'application/json'
			},
		}).then(response => {
			response.json().then( () => {

				FinexAuth.signOut();
				this.toggleUseMenu(0);
				this.props.history.push('/' + lng + '/');

				return false;
			})
		});


	};

	render() {

		let lng = this.state.lang;
		let userShortName = 'U';

		const loggedIn = (FinexAuth.isAuthenticated() === true);


		let userName = FinexAuth.getProfile().name;
		if(typeof userName !== "undefined") userShortName = userName.substr(0,1);

		if(loggedIn) {

			return (
				<div className="header-client-nav">
					<div className="header-client-signedin">
						<div className="header-client-deposits">
							<h3>BTC: 003.0324232023</h3>
							<p><span>24 H P&amp;L</span><em>+12&#37;</em></p>
						</div>
						<Link to={'/' + lng + '/myaccount'} className="header-client-to-profile">{userShortName}</Link>
						<button className="text-btn btn-menu" onClick={() => this.toggleUseMenu(1)}>&nbsp;</button>
						<div key="client-nav-full-loggedin" id="client-nav-full" className={(this.state.userMenuState === 0) ? "hidden" : ""}>
							<div className="client-nav-head">
								<Link to={'/' + lng + '/myaccount'} className="header-client-to-profile">{userShortName}</Link>
								<button className="text-btn btn-menu-close"
								        onClick={ () => this.toggleUseMenu(0) }>&#10005;</button>
							</div>
							<ul className="client-nav-buttons">
								<li><Link to={'/' + lng + '/'} onClick={ () => this.toggleUseMenu(0) }>MARKETS</Link></li>
								<li><Link to={'/' + lng + '/myaccount'} onClick={ () => this.toggleUseMenu(0) }>ACCOUNT</Link></li>
								<li><Link to={'/' + lng + '/mysecurity'} onClick={ () => this.toggleUseMenu(0) }>SECURITY</Link></li>
								<li>&nbsp;</li>
								<li className="client-nav-last">
									<button onClick={this.signOut} className="header-client-nav-logout">LOGOUT</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			)

		}else{

			return (
				<div className="header-client-nav">
					<div className="header-client-signedout">
						<button className="btn13" onClick={this.logIn}>Login</button>
						<button className="text-btn btn-menu" onClick={ () => this.toggleUseMenu(1)}>&nbsp;</button>
						<div key="client-nav-full-loggedout" id="client-nav-full" className={(this.state.userMenuState === 0) ? "hidden" : ""}>
							<div className="client-nav-head">
								<button className="btn13" onClick={this.logIn}>Login</button>
								<button className="text-btn btn-menu-close"
								        onClick={ () => this.toggleUseMenu(0) }>&#10005;</button>
							</div>
							<ul className="client-nav-buttons">
								<li><Link to={'/' + lng + '/register'}>REGISTER</Link></li>
								<li><Link to={'/' + lng + '/lostpass'}>LOST PASSWORD</Link></li>
							</ul>
						</div>
					</div>
				</div>
			)

		}
	}
}

export default withRouter(HeaderNavClient);