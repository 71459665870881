import React, { Component } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import {mainConfig} from "../configs/main";

/**
 * News listing page
 *
 */
class NewsListing extends Component {

	articledate = "";
	nid = 1;

	constructor(props, context) {
		super(props, context);

		this.state = {
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en',
			news: [],
			errors: '',
		};
	}

	componentWillMount() {

		fetch(mainConfig.api.newsList + '?lang=' + this.state.lang, {
			method: "GET",
			body: null,
			headers: {
				'Accept': 'application/json'
			},
		}).then(response => {

			response.json().then(jsondata => {

				if (typeof jsondata === "undefined") {

					let error = 'Something is wrong with the data send from the server [1] !';
					this.setState({errors: error});

				} else if (Number(jsondata.error) !== 0) {

					let error = jsondata.message;
					this.setState({errors: error});

				} else if (jsondata.news === null) {

					let error = 'Something is wrong with the data send from the server [2]!';
					this.setState({errors: error});

				} else {

					if (typeof jsondata.news === "object") {
						this.setState({news: jsondata.news});
					}
				}
			})
		})
	}

	render() {

		let lng = this.state.lang;
		let newsRows = [];
		if (this.state.news.length > 0) {

			newsRows = this.state.news.map((item) =>
				<Link to={'/' + lng + '/news/' + item.nid} key={item.nid} className="news-link">{item.title} <small>{item.articledate}</small></Link>
			);

		} else {

			newsRows = <p>No articles so far;</p>;
			if(this.state.errors.length > 0) newsRows += '<p>' + this.state.errors + '</p>';
		}

		return (
			<div className="page page-news">
				<section className="wrapper">
					<Header {...this.props}/>
					<h2 className="page-title">Latest News</h2>
					<div className="content">
						{newsRows}
					</div>
					<Footer {...this.props}/>
				</section>
			</div>
		);
	}
}

export default NewsListing;