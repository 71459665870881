import React, {Component} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import {mainConfig} from "../configs/main";
import FinexAuth from "../lib/FinexAuth";

class Login extends Component {

	constructor(props) {
		super();

		this.state = {
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en',
			topDomainDiff: 0,
			topDomainLocation: '',
			formReady: false,
			email: '',
			emailValid: 0,
			password: '',
			passwordValid: 0,
			errors: '',
			isLoading: false,
			showGAform: false,
			gacode: '',
			gacodeValid: true,
			numtries: 10 //TODO: implement me pls.
		};
	}

	/**
	 * Initial component mount
	 */
	componentWillMount() {

		if(FinexAuth.isAuthenticated()) {
			//FinexAuth.signOut();
		}

		let lowerHostname = 'abc';
		try {
			lowerHostname = window.location.hostname;
		} catch (e) {
		}

		let topHostname = 'cde';
		try {
			topHostname = window.parent.location.hostname;
		} catch (e) {
		}

		let foundUrlDiff = 0;
		if (lowerHostname == null || topHostname == null) {
			foundUrlDiff = 1;
		} else if (lowerHostname.localeCompare(topHostname) !== 0) {
			foundUrlDiff = 1;
		}

		const foundHost = window.parent.location.protocol + '//' + window.parent.location.hostname;

		this.setState({topDomainLocation: foundHost, topDomainDiff: foundUrlDiff});
	}


	/**
	 * Submit login form
	 *
	 * @param e
	 */
	handleSubmit = (e) => {

		e.preventDefault();
		this.validateLoginForm(true);

		let lng = this.state.lang;
		let isValid = this.validateLoginForm(true);

		if(isValid) {

			this.setState({isLoading: true});

			const userData = {
				email: this.state.email,
				password: this.state.password
			};

			fetch(mainConfig.api.login,{
				method: "POST",
				body: JSON.stringify(userData),
				headers: {
					'Accept': 'application/json'
				},
			}).then(response => {

				response.json().then(data => {

					this.setState({isLoading: false});

					if(typeof data.error === "undefined") {
						let error = 'Something is wrong with the data send from the form [1]!';
						this.setState({formReady: false, errors: error});
					} else if (data.error === null) {
						let error = 'Something is wrong with the data send from the form [2]!';
						this.setState({formReady: false, errors: error});
					}else{
						if(data.error > 0) {
							this.setState({formReady: false, errors: data.message});
						} else {
							if( (typeof data.profile.autk === "undefined") || (data.profile.autk.length < 3)) {
								let error = 'No data received from the service!';
								this.setState({formReady: false, errors: error});
							} else {
								if(data.profile.s2a === 0) {
									FinexAuth.saveProfile(data.profile);
									this.setState({verifyForm: true});
									this.props.history.replace('/' + lng + '/myaccount');
								}else{
									this.setState({showGAform: true});
								}
							}
						}
					}
				})
			})
		}
	};

	/**
	 * Handle input fields change
	 *
	 * @param e
	 */
	handleChange = (e) => {

		this.setState({
			[e.target.id]: e.target.value
		}, () => {
			this.validateLoginForm(true);
		});
	};

	/**
	 * Validate Login form
	 *
	 * @param doDisplay
	 * @returns {boolean}
	 */
	validateLoginForm(doDisplay) {

		let valid = true;
		let errors = '';

		this.setState({errors: errors, emailValid: 1, passwordValid: 1, formReady: true});

		if (this.state.email.length === 0) {
			errors = 'Email address too short!';
			this.setState({formReady: false, emailValid: 0});
		} else {
			if (this.state.email.length < 3) {
				errors = 'Email address too short!';
				this.setState({formReady: false, emailValid: 2});
			}

			if (!this.state.email.match(/@/) || !this.state.email.match(/\./)) {
				errors = 'Email address does not seems valid!';
				this.setState({formReady: false, emailValid: 2});
			}
		}

		if (this.state.password.length === 0) {
			this.setState({formReady: false, passwordValid: 0});
		} else {
			if (this.state.password.length < 6) {
				errors = 'Password too short!';
				this.setState({formReady: false, passwordValid: 2});
			}
		}

		if (this.state.topDomainDiff > 0) {
			errors = 'Domains does not match, someone hacking ?!';
		}

		if (doDisplay) this.setState({errors: errors});

		return valid;
	}


	/**
	 * Handle GA code
	 *
	 * @param e
	 */
	handleGAcode = (e) => {

		if( (e.target.value.length > 0 ) && (e.target.value.length !== 6)) {
			this.setState({gacode: e.target.value, gacodeValid: false});
		}else{
			this.setState({gacode: e.target.value, gacodeValid: true});
		}
	};

	/**
	 * Submit Google Authentication
	 */
	handleGASubmit = (e) => {

		e.preventDefault();
		this.validateLoginForm(true);

		let lng = this.state.lang;
		let isValid = this.validateLoginForm(true);

		if(isValid) {
			this.setState({isLoading: true});

			const userData = {
				email: this.state.email,
				password: this.state.password,
				gacode: this.state.gacode,
			};

			fetch(mainConfig.api.loginga, {
				method: "POST",
				body: JSON.stringify(userData),
				headers: {
					'Accept': 'application/json'
				},
			}).then(response => {

				response.json().then(data => {

					this.setState({isLoading: false});

					if (typeof data.error === "undefined") {
						let error = 'Something is wrong with the data send from the form [1]!';
						this.setState({formReady: false, errors: error});
					} else if (data.error === null) {
						let error = 'Something is wrong with the data send from the form [2]!';
						this.setState({formReady: false, errors: error});
					} else {
						if (data.error > 0) {
							this.setState({formReady: false, errors: data.message});
						} else {
							if ((typeof data.profile.autk === "undefined") || (data.profile.autk.length < 3)) {
								let error = 'No data received from the service!';
								this.setState({formReady: false, errors: error});
							} else {
								FinexAuth.saveProfile(data.profile);
								this.setState({verifyForm: true});
								this.props.history.replace('/' + lng + '/myaccount');
							}
						}
					}
				})
			});
		}
	};

	/**
	 * RENDER CONTENT ->
	 *
	 * @returns {*}
	 */
	render() {

		let lng = this.state.lang;

		return (
			<div className="page page-login">

				<section className="wrapper">

					<Header {...this.props}/>

					<h2 className="page-title">Login to your account</h2>

					{(this.state.showGAform === false) ?

						<div className="content sp-form-wrapper">

							<div className="sp-form-hl">
								<div className="sp-form-check-domain">
									<p>Please check that you are visiting <span>https://finexly.com</span></p>
									<input type="text" name="topdomain" id="topdomain"
									       value={this.state.topDomainLocation}
									       readOnly="readOnly"
									       className={((this.state.topDomainDiff === 1) ? "nok" : "ok")}/>
								</div>
								<form method="post" onSubmit={this.handleSubmit}>
									<div
										className={(this.state.errors.length > 0) ? 'sp-form-err has-errors' : 'sp-form-err'}>
										{this.state.errors}
									</div>
									<div className="sp-form-row">
										<div className="sp-form-group">
											<input type="email" name="email" id="email" value={this.state.email}
											       placeholder="Email Address" required="required"
											       className={(this.state.emailValid === 0) ? 'inp' : ((this.state.emailValid === 1) ? 'inp valid' : 'inp invalid')}
											       onChange={this.handleChange} onBlur={this.handleChange}
											       onKeyUp={this.handleChange}/>
										</div>
									</div>
									<div className="sp-form-row">
										<div className="sp-form-group">
											<input type="password" name="password" id="password"
											       value={this.state.password}
											       placeholder="Password" required="required"
											       className={(this.state.passwordValid === 0) ? 'inp' : ((this.state.passwordValid === 1) ? 'inp valid' : 'inp invalid')}
											       onChange={this.handleChange} onBlur={this.handleChange}
											       onKeyUp={this.handleChange}/>
										</div>
									</div>
									<div className="sp-form-row">
										<Link to={'/' + lng + '/lostpass'} className="sp-lost-pass-lnk">Forgot
											password?</Link>
									</div>
									<div className="sp-form-row">
										{(this.state.isLoading === true) ?
											<button type="button" className="sp-form-button"
											        disabled="disabled"><img src="/assets/ic/loading.gif" width={12}
											                                 alt="Loading"/></button> :
											<button type="submit" name="action" className="sp-form-button"
											        value="loginnow"
											        disabled={this.state.formReady ? false : "disabled"}>LOGIN
											</button>
										}
									</div>
								</form>
							</div>
							<div className="sp-form-alternatives">
								Not yet in FINEXLY? <Link to={'/' + lng + '/register'}>Register</Link>
							</div>
						</div> :
						<div>

							<div className="content sp-form-wrapper">

								<div className="sp-form-hl">
									<h4 className="sp-form-registration-title">Enter the code from Google Authentication app:</h4>
									<div className={(this.state.errors.length > 0) ? 'sp-form-err has-errors' : 'sp-form-err'}>
										{this.state.errors}
									</div>
									<div className="sp-form-ga-login">
										<input type="text" name="gacode" id="gacode" placeholder="XXXXXX"
										       value={this.state.gacode} maxLength={6}
										       className={((this.state.gacodeValid) ? "ok" : "nok")}
										       onKeyUp={this.handleGAcode} onKeyDown={this.handleGAcode}
										       onChange={this.handleGAcode} />
									</div>
									<form method="post" onSubmit={this.handleGASubmit} className="ga-login-buttons-area">
										<button className="sp-form-button">Verify</button>
									</form>
								</div>
								<div className="sp-form-alternatives">
									Lost access to your app/code? <Link to={'/' + lng + '/lostpass'}>Recover access</Link>
								</div>

							</div>
						</div>
					}

					<Footer {...this.props}/>
				</section>
			</div>
		);
	}
}

export default Login;