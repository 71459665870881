import React, {Component} from 'react';
import AsideBlock from "../components/AsideBlock";
import HeaderWithRates from "../components/HeaderWithRates";
import Footer from "../components/Footer";
import MakeOrderSVG from '../assets/svgs/makeorder.svg';
import TradesSVG from '../assets/svgs/trades.svg';
import BuyOrdersSVG from '../assets/svgs/buyorders.svg';
import SellOrdersSVG from '../assets/svgs/sellorders.svg';
import {TVChartContainer} from "../components/TVChartContainer";
import {mainConfig} from "../configs/main";
import Nouislider from "nouislider-react";
import Link from "react-router-dom/es/Link";
import FinexAuth from "../lib/FinexAuth";
import Websocket from 'react-websocket';
import {roundCurrencyVal} from "../lib/FinexLib";

/**
 * Pair page
 */
class Pair extends Component {

	refPairWs;
	intervalId = null;
	pair1id = 0;
	pair2id = 0;
	executedPerc = 0;
	afterOrderExecuted = 0;
	created = '';
	ordersBuy = [];
	ordersSell = [];
	mytrades = [];
	myorders = [];
	execPerc = 0;

	constructor() {
		super();

		this.state = {
			profile: {},
			pair: '',
			win: {width: 0, height: 0},
			asideToggled: false,
			clientInfo: {
				cuBallanceBase: 0,
				cuBallanceQuote: 0
			},
			pairInfo: {
				base: '',
				baseId: 0,
				quote: '',
				quoteId: 0,
				label: '',
				name: '-- | --',
				ico: '/assets/ic/loading.gif',
				lastprice: '--',
				change: '',
				high: '',
				low: '',
				volume: ''
			},
			tradeForm: {
				buyPrice: 0,
				sellPrice: 0,
				buyAmount: 0,
				sellAmount: 0,
				buyLimit: 0,
				sellLimit: 0,
				buyTotal: 0,
				sellTotal: 0,
				buyErrorMsg: '',
				sellErrorMsg: '',
				isTradeingNow: false,
				afterOrderState: -1,
				afterOrderExecuted: -1,
				afterOrderMsg: '',
			},
			history: {
				trades: [],
				seltab: 0
			},
			myhistory:{
				trades: [],
				orders: [],
			},
			orders:{
				buy:[],
				sell:[],
			},
			newTradeTabSel: 1
		};
	}

	/**
	 * Component just mounts
	 */
	componentDidMount() {


		if (typeof this.props.match.params.pair !== "undefined") {

			let setPairFromURI = this.props.match.params.pair;
			setPairFromURI.replace(/[^0-9A-Z-]/, '');

			this.setState({pair: setPairFromURI}, () => {
				this.readTradeData();
			})
		} else {
			window.location.href = '/' + this.props.lng + '/';
		}

		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);

		this.intervalId = setInterval(() => this.readTradeData(), 6000);
		this.readTradeData();

		this.setState({profile: this.props.auth.getProfile()});

	}

	/**
	 * Page gets unloaded
	 */
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
		clearInterval(this.intervalId);
	}

	/**
	 * Update some dimmensions
	 */
	updateWindowDimensions = () => {
		this.setState({win: {width: window.innerWidth, height: window.innerHeight}});
		if (window.innerWidth > 1555) this.setState({asideToggled: false});

		this.fixBlocksHeight();
	};

	/**
	 * Fix Blocks height
	 */
	fixBlocksHeight = () => {

		let rightBlock = document.getElementById('exchange-forms-area');
		if (typeof rightBlock.scrollHeight !== "undefined") {
			let rightTopBlockHeight = parseInt(rightBlock.scrollHeight.toString());

			if (rightTopBlockHeight > 0) {

				let chartArea = document.getElementById('exchange-chart-area');
				if (typeof chartArea !== "undefined") chartArea.style.height = rightTopBlockHeight + 'px';

				let tvChartArea = document.getElementById('tv_chart_container');
				if (typeof tvChartArea !== "undefined") tvChartArea.style.height = rightTopBlockHeight + 'px';

			}
		}

	};

	/**
	 * After succesfull connection to WSS send message
	 */
	handleTradesData = (JSONdata) => {

		let itemsToUpdate = {};

		if(JSONdata.match(/^{/)) {
			let result = JSON.parse(JSONdata);
			if(typeof result.history !== "undefined") {
				itemsToUpdate.history = this.state.history;
				itemsToUpdate.history.trades = result.history;
			}
			if(typeof result.ordersBuy !== "undefined") {
				itemsToUpdate.orders = this.state.orders;
				itemsToUpdate.orders.buy = result.ordersBuy;
			}
			if(typeof result.ordersSell !== "undefined") {
				itemsToUpdate.orders = this.state.orders;
				itemsToUpdate.orders.sell = result.ordersSell;
			}

			this.setState(itemsToUpdate);
		}


	};

	/**
	 * Request the pair data
	 *
	 */
	handleTradesDataOpen = () => {
		this.refPairWs.sendMessage('{"pair": "' + this.props.match.params.pair + '"}');
	};

	/**
	 * Read page data from the API
	 */
	readTradeData = () => {

		if (this.state.pair.length < 3) return;

		const userData = {
			uid: this.state.profile.uid,
			autk: this.state.profile.autk,
			pair: this.state.pair,
			historytab: this.state.history.seltab
		};

		fetch(mainConfig.api.pair, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				'Accept': 'application/json'
			},
		}).then(response => response.json())
			.then(jsondata => {

				if (typeof jsondata === "undefined") {

					let error = 'Something is wrong with the data sent from the server [1] !';
					this.setState({errors: error});

				} else if (Number(jsondata.error) !== 0) {

					let error = jsondata.message;
					this.setState({errors: error});

				} else {

					let itemsToUpdate = {};

					itemsToUpdate.myhistory = {};

					itemsToUpdate.myhistory.trades = jsondata.mytrades;
					itemsToUpdate.myhistory.orders = jsondata.myorders;

					itemsToUpdate.clientInfo = this.state.clientInfo;

					if(this.state.profile.uid > 0) {
						itemsToUpdate.clientInfo.cuBallanceQuote = 3.454434;
						itemsToUpdate.clientInfo.cuBallanceBase = 5.454434;
					}else{
						itemsToUpdate.clientInfo.cuBallanceQuote = 0;
						itemsToUpdate.clientInfo.cuBallanceBase = 0;
					}

					itemsToUpdate.pairInfo = {
						base: jsondata.pair.pair1,
						baseId: jsondata.pair.pair1id,
						quote: jsondata.pair.pair2,
						quoteId: jsondata.pair.pair2id,
						label: jsondata.pair.label,
						name: jsondata.pair.name,
						ico: mainConfig.api.icos + jsondata.pair.ico + ".png",
						lastprice: jsondata.pair.last,
						change: jsondata.pair.change,
						high: jsondata.pair.high,
						low: jsondata.pair.low,
						volume: jsondata.pair.vol,
					};

					let tradeInfo = this.state.tradeForm;

					if (this.state.newTradeTabSel === 0) {

						tradeInfo.buyPrice = jsondata.pair.last;
						tradeInfo.sellPrice = jsondata.pair.last;

						itemsToUpdate.tradeForm = tradeInfo;

						this.setState(itemsToUpdate);

					} else {

						if(tradeInfo.buyPrice === 0 || tradeInfo.sellPrice === 0) {

							tradeInfo.buyPrice = jsondata.pair.last;
							tradeInfo.sellPrice = jsondata.pair.last;

							itemsToUpdate.tradeForm = tradeInfo;

							this.setState(itemsToUpdate);
						} else {
							this.setState(itemsToUpdate);
						}

					}

					this.fixBlocksHeight();
				}
			})
			.catch(err => console.error(this.props.url, err.toString()))

	};

	/**
	 * Show/Hide aside panel
	 */
	toggleAsidePanel = () => {

		if (this.state.win.width < 1600) {
			if (this.state.asideToggled === false) {
				this.setState({asideToggled: true})
			} else {
				this.setState({asideToggled: false})
			}
		} else {
			this.setState({asideToggled: false})
		}
	};

	/**
	 * Change new trade tabs
	 *
	 */
	handleNewTradeType = (event) => {

		let tabNum = Number(event.target.getAttribute('data-tabnum'));
		if (!isNaN(tabNum)) {

			let tradeForm = this.state.tradeForm;

			let itemsToUpdate = {};
			itemsToUpdate.newTradeTabSel = tabNum;
			itemsToUpdate.tradeForm = tradeForm;


			if (tabNum === 0 && typeof this.state.pairInfo.lastprice !== "undefined") {
				itemsToUpdate.tradeForm.buyPrice = this.state.pairInfo.lastprice;
				itemsToUpdate.tradeForm.sellPrice = this.state.pairInfo.lastprice;
			}

			this.setState(itemsToUpdate, () => {
				this.fixBlocksHeight();
			});
		}

	};

	/**
	 * Change buy amount slider
	 *
	 */
	handleTradeBuySlider = (value) => {

		if (typeof value === "undefined") return;
		if (typeof value[0] === "undefined") return;

		let sliderVal = parseFloat(value[0]);
		if (sliderVal > 0.000000000000000) {

			let sldAmountCalc = (this.state.clientInfo.cuBallanceBase / 100) * sliderVal;

			let tradeForm = this.state.tradeForm;
			tradeForm.buyTotal = roundCurrencyVal(sldAmountCalc);
			tradeForm.buyAmount = roundCurrencyVal((tradeForm.buyTotal / tradeForm.buyPrice));
			this.setState({tradeForm: tradeForm});

		}

	};

	/**
	 * Change sell amount slider
	 *
	 */
	handleTradeSellSlider = (value) => {

		if (typeof value === "undefined") return;
		if (typeof value[0] === "undefined") return;

		let sliderVal = parseFloat(value[0]);
		if (sliderVal > 0.000000000000000) {

			let sldAmountCalc = (this.state.clientInfo.cuBallanceQuote / 100) * sliderVal;

			let tradeForm = this.state.tradeForm;
			tradeForm.buyErrorMsg = "";
			tradeForm.sellErrorMsg = "";

			tradeForm.sellTotal = roundCurrencyVal(sldAmountCalc);
			tradeForm.sellAmount = roundCurrencyVal((tradeForm.sellTotal / tradeForm.sellPrice));
			this.setState({tradeForm: tradeForm});

		}

	};


	handleTradeBuyPrice = (event) => {

		let tradeForm = this.state.tradeForm;
		tradeForm.buyErrorMsg = "";
		tradeForm.sellErrorMsg = "";

		tradeForm.buyPrice = event.target.value.replace(/[^0-9.]/, '');
		this.setState({tradeForm: tradeForm});
	};

	handleTradeBuyLimit = (event) => {

		let tradeForm = this.state.tradeForm;
		tradeForm.buyErrorMsg = "";
		tradeForm.sellErrorMsg = "";

		tradeForm.sellLimit = event.target.value.replace(/[^0-9.]/, '');
		this.setState({tradeForm: tradeForm});
	};

	handleTradeBuyAmount = (event) => {

		let tradeForm = this.state.tradeForm;
		tradeForm.buyErrorMsg = "";
		tradeForm.sellErrorMsg = "";

		let buyAmount = event.target.value.replace(/[^0-9.]/, '');
		tradeForm.buyAmount = buyAmount;
		tradeForm.buyTotal = roundCurrencyVal(tradeForm.buyPrice * buyAmount);

		this.setState({tradeForm: tradeForm});
	};

	handleTradeBuyTotal = (event) => {

		let tradeForm = this.state.tradeForm;
		tradeForm.buyErrorMsg = "";
		tradeForm.sellErrorMsg = "";

		let buyTotal = event.target.value.replace(/[^0-9.]/, '');
		tradeForm.buyTotal = buyTotal;
		if (this.state.tradeForm.buyPrice > 0.000000000001) {
			tradeForm.buyAmount = roundCurrencyVal(buyTotal / this.state.tradeForm.buyPrice);
		}

		this.setState({tradeForm: tradeForm});
	};

	handleTradeSellPrice = (event) => {

		let tradeForm = this.state.tradeForm;
		tradeForm.buyErrorMsg = "";
		tradeForm.sellErrorMsg = "";

		tradeForm.sellPrice = event.target.value.replace(/[^0-9.]/, '');
		this.setState({tradeForm: tradeForm});
	};

	handleTradeSellLimit = (event) => {

		let tradeForm = this.state.tradeForm;
		tradeForm.buyErrorMsg = "";
		tradeForm.sellErrorMsg = "";

		tradeForm.sellLimit = event.target.value.replace(/[^0-9.]/, '');
		this.setState({tradeForm: tradeForm});
	};

	handleTradeSellAmount = (event) => {

		let tradeForm = this.state.tradeForm;
		tradeForm.buyErrorMsg = "";
		tradeForm.sellErrorMsg = "";

		let sellAmount = event.target.value.replace(/[^0-9.]/, '');
		tradeForm.sellAmount = sellAmount;
		tradeForm.sellTotal = roundCurrencyVal(tradeForm.sellPrice * sellAmount);

		this.setState({tradeForm: tradeForm});
	};

	handleTradeSellTotal = (event) => {

		let tradeForm = this.state.tradeForm;
		tradeForm.buyErrorMsg = "";
		tradeForm.sellErrorMsg = "";

		let sellTotal = event.target.value.replace(/[^0-9.]/, '');
		tradeForm.sellTotal = sellTotal;
		if (this.state.tradeForm.sellPrice > 0.000000000001) {
			tradeForm.sellAmount = roundCurrencyVal(sellTotal / this.state.tradeForm.sellPrice);
		}
		this.setState({tradeForm: tradeForm});
	};


	/**
	 * Order buy form handler
	 */
	handleOrderBuyForm = (event) => {
		event.preventDefault();

		let tradeForm = this.state.tradeForm;
		tradeForm.isTradeingNow = true;
		tradeForm.buyErrorMsg = "";
		tradeForm.sellErrorMsg = "";

		this.setState({tradeForm: tradeForm}, () => {

			let readyToSubmit = true;

			if(this.state.clientInfo.cuBallanceBase < this.state.tradeForm.buyTotal) {
				readyToSubmit = false;
				tradeForm.buyErrorMsg = 'You do not have enough funds available to make the order.';
			}

			window.setTimeout(() => {

				if(readyToSubmit) {

					this.sendOrderRequest(tradeForm, false);

				}else{

					tradeForm.isTradeingNow = false;
					this.setState({tradeForm: tradeForm});
					this.fixBlocksHeight();

				}

			},200);
		});
	};

	/**
	 * Order sell form hangler
	 */
	handleOrderSellForm = (event) => {
		event.preventDefault();

		let tradeForm = this.state.tradeForm;
		tradeForm.isTradeingNow = true;
		tradeForm.buyErrorMsg = "";
		tradeForm.sellErrorMsg = "";

		this.setState({tradeForm: tradeForm}, () => {

			let readyToSubmit = true;

			if(this.state.clientInfo.cuBallanceBase < this.state.tradeForm.buyTotal) {
				readyToSubmit = false;
				tradeForm.buyErrorMsg = 'You do not have enough funds available to make the order.';
			}

			window.setTimeout(() => {

				if(readyToSubmit) {

					this.sendOrderRequest(tradeForm, true);

				}else{

					tradeForm.isTradeingNow = false;
					this.setState({tradeForm: tradeForm});
					this.fixBlocksHeight();

				}

			},200);

		});
	};

	/**
	 * Send order JSON request
	 *
	 * @param tradeForm
	 * @param isSell boolean
	 */
	sendOrderRequest = (tradeForm, isSell) => {

		const userData = {
			uid: this.state.profile.uid,
			autk: this.state.profile.autk,
			type: this.state.newTradeTabSel,
			base: this.state.pairInfo.base,
			baseid: this.state.pairInfo.baseId,
			quote: this.state.pairInfo.quote,
			quoteid: this.state.pairInfo.quoteId,
		};

		if(isSell) {

			userData.price = this.state.tradeForm.sellPrice;
			userData.limit = this.state.tradeForm.sellLimit;
			userData.amount = this.state.tradeForm.sellAmount;
			userData.total = this.state.tradeForm.sellTotal;
			userData.issell = 1;

		} else {

			userData.price = this.state.tradeForm.buyPrice;
			userData.limit = this.state.tradeForm.buyLimit;
			userData.amount = this.state.tradeForm.buyAmount;
			userData.total = this.state.tradeForm.buyTotal;
			userData.issell = 0;
		}

		fetch(mainConfig.api.neworder, {
			method: "POST",
			body: JSON.stringify(userData),
			headers: {
				'Accept': 'application/json'
			},
		}).then(response => {

			response.json().then(data => {

				let itemsToUpdate = {};
				itemsToUpdate.tradeForm = tradeForm;
				itemsToUpdate.tradeForm.isTradeingNow = false;

				if (typeof data.error === "undefined") {

					itemsToUpdate.tradeForm.buyErrorMsg = 'Something is wrong with the data send from the form [1]!';
					itemsToUpdate.tradeForm.isTradeingNow = false;

				} else if (data.error === null) {

					itemsToUpdate.tradeForm.buyErrorMsg = 'Something is wrong with the data send from the form [2]!';
					itemsToUpdate.tradeForm.isTradeingNow = false;

				} else {

					if (data.error > 0) {

						itemsToUpdate.tradeForm.buyErrorMsg = data.message;
						itemsToUpdate.tradeForm.isTradeingNow = false;

					} else {

						if ( (typeof data.profile === "undefined") || (typeof data.profile.autk === "undefined") || (data.profile.autk.length < 3)) {

							itemsToUpdate.tradeForm.buyErrorMsg = 'No data received from the service!';
							itemsToUpdate.tradeForm.isTradeingNow = false;

						} else {

							if ( typeof data.order !== "undefined" && typeof data.order.status !== "undefined") {

								itemsToUpdate.tradeForm.buyAmount = 0;
								itemsToUpdate.tradeForm.sellAmount = 0;
								itemsToUpdate.tradeForm.buyLimit = 0;
								itemsToUpdate.tradeForm.sellLimit = 0;
								itemsToUpdate.tradeForm.buyTotal = 0;
								itemsToUpdate.tradeForm.sellTotal = 0;
								itemsToUpdate.tradeForm.afterOrderState = data.order.state;
								itemsToUpdate.tradeForm.afterOrderExecuted = data.order.executedPerc;

								if(Number(data.order.status) === 3) {

									this.showAfterTradeMessage('error', 'Your order was not sent!', 'System error detected!');

								} else {

									let subtitle = 'Currently it is ' + data.order.executedPerc + '% executed!';
									this.showAfterTradeMessage('success', 'Your order was succesfully sent!', subtitle);

								}
							}

							FinexAuth.saveProfile(data.profile);
						}
					}
				}

				this.setState(itemsToUpdate);

			});
		});
	};



	/**
	 * Show after trade message
	 *
	 */
	showAfterTradeMessage = (className, msgTitle, msgSubtitle) => {

		let msgBlk = document.getElementById('trade-post-order-blk');
		msgBlk.innerHTML = '<h2>' + msgTitle + '&nbsp;</h2><h3>' + msgSubtitle + '&nbsp;</h3>';
		msgBlk.setAttribute('class', className);
		window.setTimeout(() => {
			msgBlk.setAttribute('class', className + ' hidden');
			msgBlk.innerHTML = '';
		},2000);
	};


	/**
	 * Change history tabs
	 *
	 */
	handleTradeHistoryType = (event) => {

		let tabNum = Number(event.target.getAttribute('data-tabnum'));
		if (!isNaN(tabNum)) {

			let history = this.state.history;
			history.seltab = tabNum;

			let itemsToUpdate = {};
			itemsToUpdate.history = history;

			this.setState(itemsToUpdate, () => {
				this.readTradeData();
			});
		}

	};

	/**
	 * Buy Sell update from tradebook list
	 *
	 * @param isBuyColumn
	 * @param rowIdx
	 * @returns {boolean}
	 */
	updateFromBuySellForm = (isBuyColumn, rowIdx) => {

		let tradeForm = this.state.tradeForm;
		tradeForm.buyErrorMsg = "";
		tradeForm.sellErrorMsg = "";

		if(isBuyColumn === 1) { // 0 = SELL, 1 = BUY

			let sumAmount = 0;
			let lastPrice = 0;

			this.state.orders.buy.every( function (row, i) {

				sumAmount += parseFloat(row.amount);
				lastPrice = parseFloat(row.price);

				return rowIdx !== i;
			});

			lastPrice = roundCurrencyVal(lastPrice);
			sumAmount = roundCurrencyVal(sumAmount);


			tradeForm.sellPrice = lastPrice;
			tradeForm.sellAmount = sumAmount;
			tradeForm.sellTotal = roundCurrencyVal(lastPrice * sumAmount);

		} else {

			let sumAmount = 0;
			let lastPrice = 0;

			this.state.orders.sell.every( function (row, i) {

				sumAmount += parseFloat(row.amount);
				lastPrice = parseFloat(row.price);

				return rowIdx !== i;
			});

			lastPrice = roundCurrencyVal(lastPrice);
			sumAmount = roundCurrencyVal(sumAmount);


			tradeForm.buyrice = lastPrice;
			tradeForm.buyAmount = sumAmount;
			tradeForm.buyTotal = roundCurrencyVal(lastPrice * sumAmount);

		}

		this.setState({tradeForm: tradeForm});

		return true;
	};


	/**
	 * Display the final page content
	 *
	 * @returns {*}
	 */
	render() {

		let buyPrice = (this.state.newTradeTabSel === 0) ? this.state.pairInfo.lastprice : this.state.tradeForm.buyPrice;
		let sellPrice = (this.state.newTradeTabSel === 0) ? this.state.pairInfo.lastprice : this.state.tradeForm.sellPrice;

		let tradesHistorySource = [];

		let tradesList;

		if(this.state.history.seltab === 1 || this.state.history.seltab === 2) {

			if(this.state.profile.uid > 0) {

				if(this.state.history.seltab === 1) {
					tradesHistorySource = this.state.myhistory.trades;
				}else{
					tradesHistorySource = this.state.myhistory.orders;
				}

			}else{

				tradesHistorySource = [];

				tradesList =
					<ul className='trade-line-note'>
						<li>You need to&nbsp;
							<Link to={'/' + this.props.lng + '/login/'}>Login</Link>&nbsp;or&nbsp;
							<Link
								to={'/' + this.props.lng + '/register/'}>Register</Link> to
							see your trades.</li>
					</ul>
			}
		}else {
			tradesHistorySource = this.state.history.trades ;
		}

		if (tradesHistorySource.length > 0) {

			tradesList = tradesHistorySource.map((trade, i) => {

				let sameClient = 0;

				if( (typeof this.state.profile.uid !== "undefined") && (this.state.profile.uid === trade.uid)) sameClient = 1;

				return <ul key={'trkine' + i} className={'trade-line trade-type-' + trade.issell + ' same-client-' + sameClient}>
					<li><span>{trade.created}</span></li>
					<li>{(trade.issell === 1) ? 'SELL' : 'BUY'}</li>
					<li>{trade.price}</li>
					<li>{trade.amount}</li>
					<li>{trade.total}</li>
				</ul>
			})

		}


		let ordersBuyList;
		if (this.state.orders.buy.length > 0) {
			ordersBuyList = this.state.orders.buy.map((trade, i) => {
				return <ul key={'obki' + i} className={'order-line buy state-' + trade.issell}
				           style={{'backgroundSize': trade.execPerc + '% 27px'}} >
					<li>
						<button onClick={() => this.updateFromBuySellForm(1, i)}>{trade.amount}</button>
					</li>
					<li>
						<button onClick={() => this.updateFromBuySellForm(1, i)}>{trade.total}</button>
					</li>
					<li>
						<button onClick={() => this.updateFromBuySellForm(1, i)}>{trade.price}</button>
					</li>
				</ul>
			})
		}

		let ordersSellList;
		if (this.state.orders.sell.length > 0) {
			ordersSellList = this.state.orders.sell.map((trade, i) => {
				return <ul key={'obki' + i} className={'order-line sell state-' + trade.issell}
				           style={{'backgroundSize': trade.execPerc + '% 27px'}}>
					<li>
						<button onClick={() => this.updateFromBuySellForm(0, i)}>{trade.price}</button>
					</li>
					<li>
						<button onClick={() => this.updateFromBuySellForm(0, i)}>{trade.amount}</button>
					</li>
					<li>
						<button onClick={() => this.updateFromBuySellForm(0, i)}>{trade.total}</button>
					</li>
				</ul>
			})
		}




		return (
			<div className="page page-exchange">
				<AsideBlock class={(this.state.asideToggled) ? 'visible' : 'hidden'}
				            isFloating={this.state.asideToggled}/>
				<section className="wrapper">

					<HeaderWithRates
						pairLabel={this.state.pairInfo.label}
						pairName={this.state.pairInfo.name}
						pairIco={this.state.pairInfo.ico}
						pairChange={this.state.pairInfo.change}
						pairHigh={this.state.pairInfo.high}
						pairLow={this.state.pairInfo.low}
						pairLast={this.state.pairInfo.lastprice}
						pairVol={this.state.pairInfo.volume}
						onToggleAside={this.toggleAsidePanel}/>

					<div className="content exhange-wrapper">
						<div className="exchange-line-chart-form">
							<div className="exchange-chart-area" id="exchange-chart-area">
								<TVChartContainer/>
							</div>
							<div className="exchange-forms-area" id="exchange-forms-area">
								<div className="exchange-area-head">
									<div className="exchange-area-head-left">
										<img src={MakeOrderSVG} alt="Make new order" width={16}/>
										<span>MAKE ORDER</span>
									</div>
									<div className="exchange-area-head-center">
										<ul className="exchange-head-nav" style={{visibility:'hidden'}}>
											<li>
												<button key="trade-market-tab-btn" data-tabnum="0"
												        onClick={this.handleNewTradeType}
												        className={(this.state.newTradeTabSel === 0) ? "active" : ""}>MARKET
												</button>
											</li>
											<li>
												<button key="trade-limit-tab-btn" data-tabnum="1"
												        onClick={this.handleNewTradeType}
												        className={(this.state.newTradeTabSel === 1) ? "active" : ""}>LIMIT
												</button>
											</li>
											<li>
												<button key="trade-stop-tab-btn" data-tabnum="2"
												        onClick={this.handleNewTradeType}
												        className={(this.state.newTradeTabSel === 2) ? "active" : ""}>STOP
												</button>
											</li>
										</ul>
									</div>
									<div className="exchange-area-head-right">&nbsp;</div>
								</div>

								<div className="exhange-area-body">
									<div className={'exhange-area-body-left trademode-' + this.state.newTradeTabSel}>
										<h3>Buy {this.state.pairInfo.base}
											<small>{this.state.pairInfo.quote} Available: <span>{this.state.clientInfo.cuBallanceBase}</span>
											</small>
										</h3>
										<form method="post" autoComplete="off" onSubmit={this.handleOrderBuyForm}>
											<div className="trade-inputs">
												<label className="tr-inp-pre" htmlFor="buyprice">
													{(this.state.newTradeTabSel === 2) ? 'Stop price:' : 'Price:'}
												</label>
												<input type="text" name="buyprice" id="buyprice"
												       value={buyPrice} required="required"
												       onKeyUp={this.handleTradeBuyPrice}
												       onChange={this.handleTradeBuyPrice}
												       onBlur={this.handleTradeBuyPrice}/>
												<span className="tr-inp-post">{this.state.pairInfo.quote}</span>
											</div>
											<div className="trade-inputs trade-limit-inp-area">
												<label className="tr-inp-pre" htmlFor="buylimit">Limit:</label>
												<input type="text" name="buylimit" id="buylimit"
												       value={this.state.tradeForm.buyLimit} required="required"
												       onKeyUp={this.handleTradeBuyLimit}
												       onChange={this.handleTradeBuyLimit}
												       onBlur={this.handleTradeBuyLimit}/>
												<span className="tr-inp-post">{this.state.pairInfo.quote}</span>
											</div>
											<div className="trade-inputs">
												<label className="tr-inp-pre" htmlFor="buyamount">Amount:</label>
												<input type="text" name="buyamount" id="buyamount" required="required"
												       value={this.state.tradeForm.buyAmount}
												       onKeyUp={this.handleTradeBuyAmount}
												       onChange={this.handleTradeBuyAmount}
												       onBlur={this.handleTradeBuyAmount}/>
												<span className="tr-inp-post">{this.state.pairInfo.base}</span>
											</div>
											<div className="trade-inputs-slider">
												<Nouislider
													className="trade-buy-slider"
													key="trade-buy-slider"
													id="trade-buy-slider"
													start={0}
													connect={[true, false]}
													range={{min: 0, max: 100}}
													onSlide={this.handleTradeBuySlider.bind(this)}/>
											</div>
											<div className="trade-inputs">
												<label className="tr-inp-pre" htmlFor="buytotal">Total:</label>
												<input type="text" name="buytotal" id="buytotal"
												       value={this.state.tradeForm.buyTotal} required="required"
												       onKeyUp={this.handleTradeBuyTotal}
												       onChange={this.handleTradeBuyTotal}
												       onBlur={this.handleTradeBuyTotal}/>
												<span className="tr-inp-post">{this.state.pairInfo.quote}</span>
											</div>
											<div className="trade-button">
												{(this.state.profile.uid > 0) ?
													(this.state.tradeForm.isTradeingNow === true) ?
														<button type="button" className="sp-form-button"
														        disabled="disabled"><img src="/assets/ic/loading.gif"
														                                 width={12}
														                                 alt="Loading"/></button> :
														<button type="submit" className="trade-submit" name="action"
														        id="trade-btn-buy" value="buy">BUY</button> :

													<div className="trade-login-or-reg">
														<Link to={'/' + this.props.lng + '/login/'}>Login</Link>&nbsp;or&nbsp;
														<Link
															to={'/' + this.props.lng + '/register/'}>Register</Link> to
														trade.
													</div>
												}
											</div>
											<div className="trade-err-msg">{this.state.tradeForm.buyErrorMsg}</div>
										</form>
									</div>
									<div className={'exhange-area-body-right trademode-' + this.state.newTradeTabSel}>
										<h3>Sell {this.state.pairInfo.base}
											<small>{this.state.pairInfo.base} Available: <span>{this.state.clientInfo.cuBallanceQuote}</span>
											</small>
										</h3>
										<form method="post" autoComplete="off" onSubmit={this.handleOrderSellForm}>
											<div className="trade-inputs">
												<label className="tr-inp-pre" htmlFor="sellprice">
													{(this.state.newTradeTabSel === 2) ? 'Stop price:' : 'Price:'}
												</label>
												<input type="text" name="sellprice" id="sellprice"
												       value={sellPrice} required="required"
												       onKeyUp={this.handleTradeSellPrice}
												       onChange={this.handleTradeSellPrice}
												       onBlur={this.handleTradeSellPrice}/>
												<span className="tr-inp-post">{this.state.pairInfo.quote}</span>
											</div>
											<div className="trade-inputs trade-limit-inp-area">
												<label className="tr-inp-pre" htmlFor="selllimit">Limit:</label>
												<input type="text" name="selllimit" id="selllimit"
												       value={this.state.tradeForm.sellLimit} required="required"
												       onKeyUp={this.handleTradeSellLimit}
												       onChange={this.handleTradeSellLimit}
												       onBlur={this.handleTradeSellLimit}/>
												<span className="tr-inp-post">{this.state.pairInfo.quote}</span>
											</div>
											<div className="trade-inputs">
												<label className="tr-inp-pre" htmlFor="sellamount">Amount:</label>
												<input type="text" name="sellamount" id="sellamount"
												       value={this.state.tradeForm.sellAmount} required="required"
												       onKeyUp={this.handleTradeSellAmount}
												       onChange={this.handleTradeSellAmount}
												       onBlur={this.handleTradeSellAmount}/>
												<span className="tr-inp-post">{this.state.pairInfo.base}</span>
											</div>
											<div className="trade-inputs-slider">
												<Nouislider
													className="trade-sell-slider"
													key="trade-buy-slider"
													id="trade-buy-slider"
													start={0}
													connect={[true, false]}
													range={{min: 0, max: 100}}
													onSlide={this.handleTradeSellSlider.bind(this)}/>
											</div>
											<div className="trade-inputs">
												<label className="tr-inp-pre" htmlFor="selltotal">Total:</label>
												<input type="text" name="selltotal" id="selltotal"
												       value={this.state.tradeForm.sellTotal} required="required"
												       onKeyUp={this.handleTradeSellTotal}
												       onChange={this.handleTradeSellTotal}
												       onBlur={this.handleTradeSellTotal}/>
												<span className="tr-inp-post">{this.state.pairInfo.quote}</span>
											</div>
											<div className="trade-button">
												{(this.state.profile.uid > 0) ?
													(this.state.tradeForm.isTradeingNow === true) ?
														<button type="button" className="sp-form-button"
														        disabled="disabled"><img src="/assets/ic/loading.gif"
														                                 width={12}
														                                 alt="Loading"/></button> :
														<button type="submit" className="trade-submit" name="action"
														        id="trade-btn-sell" value="buy">SELL</button> :
													<div className="trade-login-or-reg">
														<Link to={'/' + this.props.lng + '/login/'}>Login</Link>&nbsp;or&nbsp;
														<Link
															to={'/' + this.props.lng + '/register/'}>Register</Link> to
														trade.
													</div>
												}
											</div>
											<div className="trade-err-msg">{this.state.tradeForm.afterOrderMsg}</div>
										</form>
									</div>
								</div>
								<div id="trade-post-order-blk" className="hidden">&nbsp;</div>
							</div>
						</div>
						<div className="exchange-line-trades">
							<div className="exchange-trades">
								<div className="exchange-area-head">
									<div className="exchange-area-head-left">
										<img src={TradesSVG} alt="Trades" width={16}/>
										<span>TRADES</span>
									</div>
									<div className="exchange-area-head-center">
										<ul className="exchange-head-nav">
											<li>
												<button key="trade-history-tab-btn" data-tabnum="0"
												        className={(this.state.history.seltab === 0) ? 'active' : ''}
														onClick={this.handleTradeHistoryType}>HISTORY</button>
											</li>
											<li>
												<button key="trade-my-trades-tab-btn" data-tabnum="1"
												        className={(this.state.history.seltab === 1) ? 'active' : ''}
												        onClick={this.handleTradeHistoryType}>MY TRADES</button>
											</li>
											<li>
												<button key="trade-my-orders-tab-btn" data-tabnum="2"
												        className={(this.state.history.seltab === 2) ? 'active' : ''}
												        onClick={this.handleTradeHistoryType}>MY ORDERS</button>
											</li>
										</ul>
									</div>
									<div className="exchange-area-head-right">&nbsp;</div>
								</div>
								<div className="trades-area-body">
									<ul className="trade-history-head">
										<li>Time</li>
										<li>Type</li>
										<li>Price ({this.state.pairInfo.quote})</li>
										<li>Amount ({this.state.pairInfo.quote})</li>
										<li>Amount ({this.state.pairInfo.base})</li>
									</ul>
									<div className="trades-area-body-list">
										{tradesList}
									</div>
								</div>
							</div>
							<div className="exchange-buy-orders">

								<div className="exchange-area-head">
									<div className="exchange-area-head-left">
										<img src={BuyOrdersSVG} alt="Buy Orders" width={16}/>
										<span>BUY ORDERS</span>
									</div>
									<div className="exchange-area-head-center">
										<div className="exchange-area-buy-sell-diff">
											<span className="diff-val down">0.00154419 &asymp; 0.00012244 BTC</span>
										</div>
									</div>
									<div className="exchange-area-head-right">
										<img src={SellOrdersSVG} alt="Sell Orders" width={16}/>
										<span>SELL ORDERS</span>
									</div>
								</div>

								<div className="orders-body">
									<div className="orders-body-buy">
										<ul className="orders-body-head">
											<li>Amount ({this.state.pairInfo.base})</li>
											<li>Amount ({this.state.pairInfo.quote})</li>
											<li>Price ({this.state.pairInfo.quote})</li>
										</ul>
										<div className="orders-body-buy-list">
											{ordersBuyList}
										</div>
									</div>
									<div className="orders-body-sell">
										<ul className="orders-body-head">
											<li>Price ({this.state.pairInfo.quote})</li>
											<li>Amount ({this.state.pairInfo.quote})</li>
											<li>Amount ({this.state.pairInfo.base})</li>
										</ul>
										<div className="orders-body-sell-list">
											{ordersSellList}
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
					{(this.state.pair.length > 2) &&
						<Websocket
							url={mainConfig.api.pairws + this.state.pair} reconnect={true} debug={false}
							onOpen={this.handleTradesDataOpen}
							onMessage={this.handleTradesData.bind(this)}
							ref={Websocket => {
								this.refPairWs = Websocket;
							}}/>
					}
					<Footer {...this.props}/>
				</section>
				{this.state.asideToggled ? <div className="hover-blk" onClick={this.toggleAsidePanel}/> : null}
			</div>

		);
	}
}

export default Pair;
