import React, { Component } from 'react';

class PageNotFound extends Component {

	constructor(props) {
		super(props);

		this.state = {
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en'
		}
	}

	render() {

		let lng = this.state.lang;

		return (
			<div className="page-404">
				<h1 className="page-title">Page not found</h1>
				<a href={'/' + lng + '/'}>Back to homepage!</a>
			</div>
		);
	}
}

export default PageNotFound;