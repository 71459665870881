import React, {Component} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";

class Support extends Component {

	constructor(props) {
		super(props);
		this.state = {
			errors: '',
		};
	}

	render() {
		return (
			<div className="page page-login">
				<section className="wrapper">
					<Header {...this.props}/>
					<h2 className="page-title">Support</h2>
					<div className="content sp-form-wrapper">
						<div className="sp-form-hl">

						</div>
					</div>
					<Footer {...this.props}/>
				</section>
			</div>
		);
	}
}

export default Support;