import React, { Component } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {mainConfig} from "../configs/main";
import Notification from "../components/Notification";
import HTMLReactParser from 'html-react-parser';

/**
 * Privacy Policy page
 */
class PrivacyPolicy extends Component {

	title = '';
	seokeywords = '';
	seodescription = '';
	content = '';

	constructor(props, context) {
		super(props, context);

		this.state = {
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en',
			content: {
				title: '',
				content: '<span>&nbsp;</span>'
			},
			errors: '',
		};
	}

	componentWillMount() {

		this.setState({errors: ''});

		fetch(mainConfig.api.content + "/prvpolicy?lang=" + this.state.lang, {
			method: "GET",
			headers: {
				'Accept': 'application/json'
			},
		}).then(response => {

			response.json().then(jsondata => {

				if (typeof jsondata === "undefined") {

					let error = 'Something is wrong with the data sent from the server [1] !';
					this.setState({errors: error});

				} else if (Number(jsondata.error) !== 0) {

					let error = jsondata.message;
					this.setState({errors: error});

				} else {

					if (typeof jsondata.content === "object") {
						document.title = jsondata.content.title;
						document.getElementById('metakeywords').setAttribute("content", jsondata.content.seokeywords);
						document.getElementById('metadescription').setAttribute("content", jsondata.content.seodescription);
						this.setState({content: jsondata.content});
					}
				}

			})
		})
	}

	render() {

		let errors = this.state.errors;

		return (
			<div className="page page-cnt">
				{errors.length > 2 && <Notification msg={errors} timeout="10" redirectTo="/" type="error"/>}
				<section className="wrapper">
					<Header {...this.props}/>
					<div className="content">
						<h2>{this.state.content.title}</h2>
						<div className="page-content">{HTMLReactParser(this.state.content.content)}</div>
					</div>
					<Footer {...this.props}/>
				</section>
			</div>
		);
	}
}

export default PrivacyPolicy;