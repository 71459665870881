import React, {Component} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {mainConfig} from "../configs/main";
import {Link} from "react-router-dom";

class Coins extends Component {
	coins = []; // Just for IDE be happy

	constructor(props, context) {
		super(props, context);

		this.state = {
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en',
			searchValue: '',
			errors: '',
			coinsPureData: [],
			coins: [],
			sortItem: null,
			sortDir: null
		};
	}

	componentWillMount() {

		fetch(mainConfig.api.assets, {
			method: "GET",
			headers: {
				'Accept': 'application/json'
			},
		}).then(response => {

			response.json().then(jsondata => {

				if (typeof jsondata === "undefined") {

					let error = 'Something is wrong with the data sent from the server [1] !';
					this.setState({errors: error});

				} else if (jsondata.error !== 0) {

					let error = jsondata.message;
					this.setState({errors: error});

				} else {

					let finalCoins = [];

					if (typeof jsondata.assets === "object") {

						jsondata.assets.forEach(function (coin) {
							finalCoins.push(coin)
						});

						this.setState({coinsPureData: finalCoins}, () => {
							this.redrawData();
						})
					}
				}
			})
		})
	}

	redrawData() {

		let filteredCoins = [];

		const searchValue = this.state.searchValue;

		if (searchValue.length < 1 && this.state.sortItem === null) {

			filteredCoins = this.state.coinsPureData;

		}else {

			if (searchValue.length > 0) {

				this.state.coinsPureData.forEach(function (coin) {

					let regSearchVal = new RegExp(searchValue, "ig");
					let foundSymbol = coin.symbol.match(regSearchVal);
					let foundName = coin.name.match(regSearchVal);

					if (foundSymbol || foundName) {
						filteredCoins.push(coin);
					}
				});

			}else{
				filteredCoins = this.state.coinsPureData;
			}

			if(this.state.sortItem !== null) {

				const filterDirection = this.state.sortDir;

				if(this.state.sortItem === 'symbol') {

					filteredCoins.sort(function(coinA, coinB){
						if(filterDirection != null) {
							if(filterDirection === 'ASC') {
								if (coinA.symbol < coinB.symbol) return -1;
								if (coinA.symbol > coinB.symbol) return 1;
							}else{
								if (coinA.symbol < coinB.symbol) return 1;
								if (coinA.symbol > coinB.symbol) return -1;
							}
						}
						return 0;
					});

				}
			}
		}
		this.setState({coins: filteredCoins})
	}

	searchChange = e => {

		let searchValue = e.target.value;
		searchValue = searchValue.replace(/[^a-zA-Z0-9]+/g, "");

		this.setState({searchValue: searchValue}, () => {
			this.redrawData();
		});
	};

	sortChange = e => {

		let sortBy = e.target.getAttribute("data-sortby");
		let sortDir = this.state.sortDir;
		if (sortDir === null) {
			sortDir = 'ASC';
		} else {
			sortDir = (this.state.sortDir === 'ASC') ? 'DESC' : 'ASC';
		}
		this.setState({sortItem: sortBy, sortDir: sortDir}, () => {
			this.redrawData();
		});
	};


	render() {

		let lng = this.state.lang;
		let coinsRows = [];
		if (this.state.coins.length > 0) {
			coinsRows = this.state.coins.map((coin) =>
				<tr key={coin.symbol}>
					<td><img src={mainConfig.api.icos + coin.ico + ".png"} alt="" className="coin-ico"/><strong>{coin.symbol}</strong></td>
					<td><span>{coin.name}</span></td>
					<td>{coin.price}</td>
					<td>{coin.high}</td>
					<td>{coin.low}</td>
					<td>{coin.vol}</td>
				</tr>
			);
		} else {
			coinsRows = (
				<tr key="not-found">
					<td colSpan={3} style={{textAlign: "center"}}><em>Nothing found!</em></td>
				</tr>
			);
		}

		return (
			<div className="page page-coins">
				<section className="wrapper">
					<Header {...this.props}/>
					<h1 className="page-title">Coin listing</h1>
					<h3 className="page-title">All crypto coins we currently support!</h3>

					<div className="content">
						<div className="fin-table-hl">
							<div className="fin-table">
								<div className="fin-table-head">
									<div className="fin-table-head-left"><p><Link to={'/' + lng + '/apply-to-coin-list'} className="cons-apply-lnk">Apply to list</Link></p></div>
									<div className="fin-table-head-right">
										<input type="text" name="filter" value={this.state.searchValue}
										       className="search"
										       placeholder="Search" onChange={this.searchChange}
										       onKeyUp={this.searchChange}/>
									</div>
								</div>
								<div className="fin-table-body">
									<table>
										<thead>
										<tr>
											<th>
												<button type="button" data-sortby="symbol" onClick={this.sortChange}
												        className={
													        (this.state.sortDir != null) ?
														        'fin-order-btn ' + this.state.sortDir.toLowerCase() :
														        'fin-order-btn'
												        }>Symbol
												</button>
											</th>
											<th><button type="button" data-sortby="name" onClick={this.sortChange}
											            className={
												            (this.state.sortDir != null) ?
													            'fin-order-btn ' + this.state.sortDir.toLowerCase() :
													            'fin-order-btn'
											            }>Coin name
											</button></th>
											<th><button type="button" data-sortby="price" onClick={this.sortChange}
											            className={
												            (this.state.sortDir != null) ?
													            'fin-order-btn ' + this.state.sortDir.toLowerCase() :
													            'fin-order-btn'
											            }>Price
											</button></th>
											<th><button type="button" data-sortby="high" onClick={this.sortChange}
											            className={
												            (this.state.sortDir != null) ?
													            'fin-order-btn ' + this.state.sortDir.toLowerCase() :
													            'fin-order-btn'
											            }>High
											</button></th>
											<th><button type="button" data-sortby="low" onClick={this.sortChange}
											            className={
												            (this.state.sortDir != null) ?
													            'fin-order-btn ' + this.state.sortDir.toLowerCase() :
													            'fin-order-btn'
											            }>Low
											</button></th>
											<th><button type="button" data-sortby="volume" onClick={this.sortChange}
											            className={
												            (this.state.sortDir != null) ?
													            'fin-order-btn ' + this.state.sortDir.toLowerCase() :
													            'fin-order-btn'
											            }>Volume
											</button></th>
										</tr>
										</thead>
										<tbody>
											{coinsRows}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<Footer {...this.props}/>
				</section>
			</div>
		);
	}
}

export default Coins;