import React, {Component} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {mainConfig} from "../configs/main";
import FinexAuth from "../lib/FinexAuth";
import Notification from "../components/Notification";

class LostPassword extends Component {

	constructor(props) {
		super(props);
		this.state = {
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en',
			isLoading: false,
			formReady: false,
			step: 1,
			uid: 0,
			email: '',
			emailValid: 0,
			unlockKey: '',
			unlockKeyValid: 0,
			password: '',
			password2: '',
			passwordValid: 0,
			password2Valid: 0,
			errors: ''
		};
	}

	validateForm(doDisplay) {

		let valid = true;
		let errors = '';
		this.setState({errors: errors, emailValid: 1, passwordValid: 1, formReady: true});

		if (this.state.email.length === 0) {
			errors = 'Email address too short!';
			this.setState({formReady: false, emailValid: 0});
		} else {
			if (this.state.email.length < 3) {
				errors = 'Email address too short!';
				this.setState({formReady: false, emailValid: 2});
			}

			if (!this.state.email.match(/@/) || !this.state.email.match(/\./)) {
				errors = 'Email address does not seems valid!';
				this.setState({formReady: false, emailValid: 2});
			}
		}

		if (doDisplay) this.setState({errors: errors});

		return valid;
	}

	handleSubmit = event => {
		event.preventDefault();
		let isValid = this.validateForm(true);

		if(isValid) {


			this.setState({isLoading: true});

			const userData = {
				email: this.state.email
			};

			fetch(mainConfig.api.lostpassRequest,{
				method: "POST",
				body: JSON.stringify(userData),
				headers: {
					'Accept': 'application/json'
				},
			}).then(response => {

				response.json().then(data => {

					this.setState({isLoading: false});

					if(typeof data.error === "undefined") {
						let error = 'Something is wrong with the data send from the form [1]!';
						this.setState({formReady: false, errors: error});
					} else if (data.error === null) {
						let error = 'Something is wrong with the data send from the form [2]!';
						this.setState({formReady: false, errors: error});
					}else{
						if(data.error > 0) {
							this.setState({formReady: false, errors: data.message});
						} else {
							FinexAuth.saveProfile(data.profile);
							this.setState({step: 2});
						}
					}
				})
			})
		}

	};


	validateUnlockCodeForm(doDisplay) {

		let valid = true;
		let errors = '';
		this.setState({errors: errors, unlockKeyValid: 1, formReady: true});

		if (this.state.unlockKey.length === 0) {
			errors = 'Unlock code too short!';
			this.setState({formReady: false, unlockKeyValid: 0});
		} else {
			if (this.state.unlockKey.length < 6) {
				errors = 'Unlock code too short!';
				this.setState({formReady: false, unlockKeyValid: 2});
			}
		}

		if (doDisplay) this.setState({errors: errors});

		return valid;
	}

	handleTryCodeSubmit = event => {

		event.preventDefault();
		let isValid = this.validateUnlockCodeForm(true);

		if(isValid) {

			this.setState({isLoading: true});

			const userData = {
				email: this.state.email,
				code: this.state.unlockKey,
			};

			fetch(mainConfig.api.lostpassTryCode,{
				method: "POST",
				body: JSON.stringify(userData),
				headers: {
					'Accept': 'application/json'
				},
			}).then(response => {

				response.json().then(data => {

					this.setState({isLoading: false});

					if(typeof data.error === "undefined") {
						let error = 'Something is wrong with the data send from the form [1]!';
						this.setState({formReady: false, errors: error});
					} else if (data.error === null) {
						let error = 'Something is wrong with the data send from the form [2]!';
						this.setState({formReady: false, errors: error});
					}else{

						if(data.error > 0) {
							this.setState({formReady: false, errors: data.message});
						} else if(typeof data.profile === "undefined") {
							let error = 'Something is wrong with the data send from the form [3]!';
							this.setState({formReady: false, errors: error});
						} else {
							FinexAuth.saveProfile(data.profile);
							this.setState({step: 3});
						}
					}
				})
			})
		}

	};

	validatePasswordsForm(doDisplay) {

		let valid = true;
		let errors = '';
		this.setState({errors: errors, passwordValid: 1, password2Valid: 1, formReady: true});

		if (this.state.password.length === 0) {
			this.setState({formReady: false, passwordValid: 0});
		} else {
			if (this.state.password.length < 6) {
				errors = 'Password too short!';
				this.setState({formReady: false, passwordValid: 2});
			}
		}


		if (this.state.password2.length === 0) {
			this.setState({formReady: false, password2Valid: 0});
		} else {
			if (this.state.password2.length < 6) {
				errors = 'Second password is too short!';
				this.setState({formReady: false, password2Valid: 2});
			}
			if (this.state.password !== this.state.password2) {
				errors = 'Passwords are not the same!';
				this.setState({formReady: false, password2Valid: 2});
			}
		}

		if (doDisplay) this.setState({errors: errors});

		return valid;
	}


	handleSetPasswordSubmit = event => {

		event.preventDefault();
		let isValid = this.validatePasswordsForm(true);

		if(isValid) {

			this.setState({isLoading: true});

			const userProfile = FinexAuth.getProfile();

			const userData = {
				uid: userProfile.uid,
				autk: userProfile.autk,
				password: this.state.password
			};

			fetch(mainConfig.api.lostpassSetNewPass,{
				method: "POST",
				body: JSON.stringify(userData),
				headers: {
					'Accept': 'application/json'
				},
			}).then(response => {
				response.json().then(data => {

					let error = (typeof data.message !== "undefined") ? data.message : '';

					if(typeof data.error === "undefined") {
						let error = 'Something is wrong with the data send from the form [1]!';
						this.setState({formReady: false, errors: error});
					} else if (data.error === null) {
						let error = 'Something is wrong with the data send from the form [2]!';
						this.setState({formReady: false, errors: error});
					}else{

						if(data.error > 0) {
							this.setState({formReady: false, errors: error});
						} else {
							this.setState({step: 4});
						}
					}
				})
			}).catch(function (error) {
				console.log(error);
			});
		}

	};

	handleChange = event => {

		if(event.target.id === 'email') {
			this.setState({email: event.target.value}, () => {
				this.validateForm(true);
			});
		}else if(event.target.id === 'unlockkey') {
			this.setState({unlockKey: event.target.value.toUpperCase()}, () => {
				this.validateUnlockCodeForm(true)
			});
		}else {
			this.setState({[event.target.id]: event.target.value}, () => {
				this.validatePasswordsForm(true)
			});
		}

	};

	componentWillMount() {

	}


	render() {

		let lng = this.state.lang;

		if(this.state.step === 1) {

			return (
				<div className="page page-lostpass">
					<section className="wrapper">
						<Header {...this.props}/>
						<h2 className="page-title">Recover your account</h2>
						<div className="content sp-form-wrapper">
							<div className="sp-form-hl">
								<div className="sp-form-check-domain">
									<p>Enter your email address and we will send you unlock key!</p>
								</div>
								<form method="post" onSubmit={this.handleSubmit}>
									<div
										className={(this.state.errors.length > 0) ? 'sp-form-err has-errors' : 'sp-form-err'}>
										{this.state.errors}
									</div>
									<div className="sp-form-row">
										<div className="sp-form-group">
											<input type="email" name="email" id="email" value={this.state.email}
											       placeholder="Email Address" required="required"
											       className={(this.state.emailValid === 0) ? 'inp' : ((this.state.emailValid === 1) ? 'inp valid' : 'inp invalid')}
											       onChange={this.handleChange} onBlur={this.handleChange}
											       onKeyUp={this.handleChange}/>
										</div>
									</div>
									<div className="sp-form-row">
										{(this.state.isLoading === true) ?
											<button type="button" className="sp-form-button"
											        disabled="disabled"><img src="/assets/ic/loading.gif" width="12"
											                                 alt="Loading" /></button> :
											<button type="submit" name="action" value="recover1"
											        className="sp-form-button"
											        disabled={this.state.formReady ? false : "disabled"}>SUBMIT
											</button>
										}
									</div>
								</form>
							</div>
						</div>
						<Footer {...this.props}/>
					</section>
				</div>
			)
		}else if(this.state.step === 2) {

			return (
				<div className="page page-lostpass">
					<section className="wrapper">
						<Header {...this.props}/>
						<div className="content sp-form-wrapper">
							<h3>Recover your account</h3>
							<div className="sp-form-hl">
								<div className="sp-form-check-domain">
									<p>Please, enter the unlock key from your email!</p>
								</div>
								<form method="post" onSubmit={this.handleTryCodeSubmit}>
									<div
										className={(this.state.errors.length > 0) ? 'sp-form-err has-errors' : 'sp-form-err'}>
										{this.state.errors}
									</div>
									<div className="sp-form-row">
										<div className="sp-form-group">
											<input type="text" name="unlockkey" id="unlockkey" value={this.state.unlockKey}
											       placeholder="123ABC" required="required" maxLength={6}
											       className={(this.state.unlockKeyValid === 0) ? 'inp' : ((this.state.unlockKeyValid === 1) ? 'inp valid' : 'inp invalid')}
											       onChange={this.handleChange} onBlur={this.handleChange}
											       onKeyUp={this.handleChange}/>
										</div>
									</div>
									<div className="sp-form-row">
										{(this.state.isLoading === true) ?
											<button type="button" className="sp-form-button"
											        disabled="disabled"><img src="/assets/ic/loading.gif" width="12"
											                                 alt="Loading" /></button> :
											<button type="submit" name="action" value="recover2"
											        className="sp-form-button"
											        disabled={this.state.formReady ? false : "disabled"}>SUBMIT
											</button>
										}
									</div>
								</form>
							</div>
						</div>
						<Footer {...this.props}/>
					</section>
				</div>
			)

		} else if (this.state.step === 3 || this.state.step === 4) {

			return (
				<div className="page page-lostpass">
					{this.state.step === 4 && <Notification msg="Password successfully changed!" timeout="5" redirectTo={'/' + lng + '/'} type="success" />}
					<section className="wrapper">
						<Header {...this.props}/>
						<div className="content sp-form-wrapper">
							<h3>Recover your account</h3>
							<div className="sp-form-hl">
								<div className="sp-form-check-domain">
									<p>Enter new password!</p>
								</div>
								<form method="post" onSubmit={this.handleSetPasswordSubmit}>
									<div
										className={(this.state.errors.length > 0) ? 'sp-form-err has-errors' : 'sp-form-err'}>
										{this.state.errors}
									</div>
									<div className="sp-form-row">
										<div className="sp-form-group">
											<input type="password" name="password" id="password"
											       value={this.state.password}
											       placeholder="Password" required="required"
											       className={(this.state.passwordValid === 0) ? 'inp' : ((this.state.passwordValid === 1) ? 'inp valid' : 'inp invalid')}
											       onChange={this.handleChange} onBlur={this.handleChange}
											       onKeyUp={this.handleChange}/>
										</div>
									</div>
									<div className="sp-form-row">
										<div className="sp-form-group">
											<input type="password" name="password2" id="password2"
											       value={this.state.password2}
											       placeholder="Confirm Password" required="required"
											       className={(this.state.password2Valid === 0) ? 'inp' : ((this.state.password2Valid === 1) ? 'inp valid' : 'inp invalid')}
											       onChange={this.handleChange} onBlur={this.handleChange}
											       onKeyUp={this.handleChange}/>
										</div>
									</div>
									<div className="sp-form-row">
										{(this.state.isLoading === true) ?
											<button type="button" className="sp-form-button"
											        disabled="disabled"><img src="/assets/ic/loading.gif" width="12"
											                                 alt="Loading" /></button> :
											<button type="submit" name="action" value="recover2"
											        className="sp-form-button"
											        disabled={this.state.formReady ? false : "disabled"}>SUBMIT
											</button>
										}
									</div>
								</form>
							</div>
						</div>
						<Footer {...this.props}/>
					</section>
				</div>
			)
		}else{

			this.props.history.replace('/');

			return (
				<div>Have you lost your self ?</div>
			)
		}
	}
}

export default LostPassword;