import React, {Component} from 'react';
import {mainConfig} from "../configs/main";
import Griddle, {plugins, RowDefinition, ColumnDefinition} from 'griddle-react';
import Cookies from 'universal-cookie';
import {arrayRemove, inArray} from "../lib/FinexLib";
import ExchangesFilter from "./ExchangesFilter";

/**
 * Floating pair block
 *
 */
class PairsFloating extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			pairs: null,
			origPairs: null
		};
	}

	componentDidMount() {

		let setFavCurrencies = [];
		const cookies = new Cookies();
		let favcurr = cookies.get("favpairs");
		if ( (typeof favcurr !== "undefined") && (favcurr != null ) && ( favcurr !== "") ) setFavCurrencies = favcurr;

		fetch(mainConfig.api.pairs)
			.then(response => response.json())
			.then(jsondata => {

				if (typeof jsondata === "undefined") {

					let error = 'Something is wrong with the data sent from the server [1] !';
					this.setState({errors: error});

				} else if (Number(jsondata.error) !== 0) {

					let error = jsondata.message;
					this.setState({errors: error});

				} else {

					let finalPairs = [];

					if(typeof jsondata.pairs === "object") {

						jsondata.pairs.forEach(function (pair) {

							let isFav = (inArray(pair.label, setFavCurrencies));

							let comp = {
								fav: isFav,
								pair: {
									fav: isFav,
									p1: pair.pair1,
									p2: pair.pair2,
									name: pair.coinname
								},
								price: pair.price,
								change: pair.change,
								volume: pair.vol,
							};
							finalPairs.push(comp)
						});
						this.setState({pairs: finalPairs, origPairs: finalPairs})
					}
				}
			})
			.catch(err => console.error(this.props.url, err.toString()))
	}

	setFavsOnlyState = (state) => {

		let setFavCurrencies = [];

		const cookies = new Cookies();
		let favcurr = cookies.get("favpairs");
		if ( (typeof favcurr !== "undefined") && (favcurr != null ) && ( favcurr !== "") ) {
			setFavCurrencies = favcurr;
		}

		let finalPairs = [];

		if(state=== true) {

			if(typeof this.state.origPairs === "object") {

				this.state.origPairs.forEach(function (pairs) {
					let label = pairs.pair.p1 + '/' + pairs.pair.p2;
					let isFav = (inArray(label, setFavCurrencies));
					if(isFav) finalPairs.push(pairs)
				});

				this.setState({pairs: finalPairs})
			}
		}else{
			this.setState({pairs: this.state.origPairs})
		}
	};

	addToFav = e => {

		this.setState({searchValue: ""});


		let setFavCurrencies = [];

		const cookies = new Cookies();
		let favcurr = cookies.get("favpairs");
		if ( (typeof favcurr !== "undefined") && (favcurr != null ) && ( favcurr !== "") ) {
			setFavCurrencies = favcurr;
		}

		if(inArray(e.target.value, setFavCurrencies)) {
			arrayRemove(e.target.value, setFavCurrencies);
		}else{
			setFavCurrencies.push(e.target.value)
		}

		this.setState({favorites: setFavCurrencies});

		let expires = new Date();
		expires.setTime(expires.getTime() + ((5 * 86400) * 1000));

		cookies.set('favpairs', JSON.stringify(setFavCurrencies), { path: '/', expires: expires});

		let finalPairs = [];

		if(typeof this.state.pairs === "object") {

			this.state.pairs.forEach(function (pairs) {
				let label = pairs.pair.p1 + '/' + pairs.pair.p2;
				pairs.pair.fav = (inArray(label, setFavCurrencies));
				finalPairs.push(pairs)
			});

			this.setState({pairs: finalPairs, origPairs : finalPairs});

		}
	};

	render() {

		const SymbolsColumn = (inp) => {

			let value = inp.value;

			let itemPair1 = "", itemPair2 = "", itemName = "", fav = false;
			let entr = Array.from(value.entries());
			entr.forEach(function (items) {
				if (items[0] === 'p1') {
					itemPair1 = items[1];
				} else if (items[0] === 'p2') {
					itemPair2 = items[1];
				} else if (items[0] === 'name') {
					itemName = items[1];
				} else if (items[0] === 'fav') {
					fav = items[1];
				}
			});

			let pair = itemPair1 + '/' + itemPair2;

			return <span className="exch-pair-nm" title={itemName}>
						<button className={fav ? 'add2fav selected' : 'add2fav' } onClick={this.addToFav} value={pair}>&nbsp;</button>
						<span className="exch-pairs">
							<em><strong>{itemPair1}</strong>/{itemPair2}</em>
						</span>
					</span>;
		};

		const ChangeColumn = ({value}) => {

			let changeClass = "nochange";

			if (value != null) {
				let pairValue = parseFloat(value);
				if (pairValue > 0.000000000) {
					changeClass = "up";
					value = "+" + pairValue.toFixed(2);
				} else {
					changeClass = "down";
					value = "-" + Math.abs(pairValue).toFixed(2);
				}
			}

			return <span className="cu-price"><em className={changeClass}>{value}&#37;</em></span>;
		};

		const NewLayout = ({Table, Filter}) => (
			<div className={this.props.className} id="markets-aside-hldiv">
				<div className="markets-filters-hl">
					<Filter onClick={this.setFavsOnlyState} groupItem="floating"/>
				</div>
				<div className="markets-filter-main-table">
					<Table/>
				</div>
			</div>
		);

		const pageProperties={
			showPager: false,
			currentPage: 1,
			pageSize: 1000,
		};

		return (
			<Griddle data={this.state.pairs} plugins={[plugins.LocalPlugin]} ref="Griddle" pageProperties={pageProperties}
			         components={{ Layout: NewLayout, Filter: ExchangesFilter }}>
				<RowDefinition>
					<ColumnDefinition id="pair" title="Pair" order={1} customComponent={SymbolsColumn} />
					<ColumnDefinition id="price" title="Last price" order={2}/>
					<ColumnDefinition id="change" title="24h Change" order={3} customComponent={ChangeColumn} />
					<ColumnDefinition id="volume" title="24h Volume" order={4} />
				</RowDefinition>
			</Griddle>
		);

	}
}

export default PairsFloating;

