import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import {FormattedMessage} from 'react-intl';

class Footer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			theme: 0,
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en'
		};
	}

	componentDidMount() {
		let themeSwitchAudio = document.getElementById("theme-switch-audio");
		themeSwitchAudio.volume = 0.4;

	}

	swithTheme() {
		let themeSwitchAudio = document.getElementById("theme-switch-audio");
		themeSwitchAudio.play();

		if(this.state.theme === 0){
			this.setState({ theme:1 });
			document.body.classList.add('theme-dark');
		}else{
			this.setState({ theme:0 });
			document.body.classList.remove('theme-dark');
		}
	}

	render() {

		let lng = this.state.lang;

		return (
			<footer className="footer">
				<div className="footer-copyrights">2018 &copy; FINEXLY.COM</div>
				<div className="footer-links">
					<a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">TWITTER</a>
					<span className="footer-dot">&#8231;</span>
					<Link to={'/' + lng + '/news/'}><FormattedMessage id="footer.news" defaultMessage="NEWS"/></Link>
					<span className="footer-dot">&#8231;</span>
					<Link to={'/' + lng + '/fees/'}><FormattedMessage id="footer.fees" defaultMessage="FEES"/></Link>
					<span className="footer-dot">&#8231;</span>
					<Link to={'/' + lng + '/coins/'}><FormattedMessage id="footer.coins" defaultMessage="COIN LISTING"/></Link>
					<span className="footer-dot">&#8231;</span>
					<Link to={'/' + lng + '/support/'}><FormattedMessage id="footer.support" defaultMessage="SUPPORT"/></Link>
					<span className="footer-dot">&#8231;</span>
					<Link to={'/' + lng + '/apidocs/'}><FormattedMessage id="footer.api" defaultMessage="API"/></Link>
					<span className="footer-dot">&#8231;</span>
					<Link to={'/' + lng + '/privacypolicy/'}><FormattedMessage id="footer.privacypolicy" defaultMessage="PRIVACY POLICY"/></Link>
					<span className="footer-dot">&#8231;</span>
					<Link to={'/' + lng + '/termofuse/'}><FormattedMessage id="footer.termofuse" defaultMessage="TERMS OF USE"/></Link>
				</div>
				<div className="footer-systemstat">
					<span><FormattedMessage id="footer.system" defaultMessage="SYSTEM"/><em className="sysstat ok">&#9679;</em></span>
				</div>
				<div className="footer-toggle-theme">
					<button className="text-btn" onClick={() => {this.swithTheme()}}>&nbsp;</button>
					<audio id="theme-switch-audio">
						<source src="/assets/audio/switch-on.ogg" type="audio/ogg" />
						<source src="/assets/audio/switch-on.mp3" type="audio/mpeg" />
					</audio>
				</div>
			</footer>
		);
	}
}

export default Footer;
