import React, {Component} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {mainConfig} from "../configs/main";

import {FormattedMessage} from 'react-intl';

class Fees extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			searchValue: '',
			errors: '',
			feesPureData: [],
			fees: [],
			sortItem: null,
			sortDir: null
		};
	}

	componentWillMount() {

		fetch(mainConfig.api.fees, {
			method: "GET",
			headers: {
				'Accept': 'application/json'
			},
		}).then(response => {

			response.json().then(jsondata => {

				if (typeof jsondata === "undefined") {
					let error = 'Something is wrong with the data sent from the server [1] !';
					this.setState({errors: error});
				} else if (jsondata.error !== 0) {
					let error = jsondata.message;
					this.setState({errors: error});
				} else {

					let finalFees = [];

					if (typeof jsondata.fees === "object") {
						jsondata.fees.forEach(function (fee) {
							finalFees.push(fee)
						});
						this.setState({feesPureData: finalFees}, () => {
							this.redrawData();
						})
					}
				}
			})
		})
	}

	redrawData() {

		let filteredFees = [];

		const searchValue = this.state.searchValue;

		if (searchValue.length < 1 && this.state.sortItem === null) {
			filteredFees = this.state.feesPureData;
		}else {

			if (searchValue.length > 0) {
				this.state.feesPureData.forEach(function (fee) {

					let regSearchVal = new RegExp(searchValue, "ig");
					let foundSymbol = fee.symbol.match(regSearchVal);
					let foundName = fee.name.match(regSearchVal);

					if (foundSymbol || foundName) {
						filteredFees.push(fee);
					}
				});
			}else{
				filteredFees = this.state.feesPureData;
			}

			if(this.state.sortItem !== null) {

				const filterDirection = this.state.sortDir;

				if(this.state.sortItem === 'symbol') {
					filteredFees.sort(function(feeA, feeB){
						if(filterDirection != null) {
							if(filterDirection === 'ASC') {
								if (feeA.symbol < feeB.symbol) return -1;
								if (feeA.symbol > feeB.symbol) return 1;
							}else{
								if (feeA.symbol < feeB.symbol) return 1;
								if (feeA.symbol > feeB.symbol) return -1;
							}
						}
						return 0;
					})
				}
			}
		}
		this.setState({fees: filteredFees})
	}

	searchChange = e => {

		let searchValue = e.target.value;
		searchValue = searchValue.replace(/[^a-zA-Z0-9]+/g, "");

		this.setState({searchValue: searchValue}, () => {
			this.redrawData();
		});
	};

	sortChange = e => {

		let sortBy = e.target.getAttribute("data-sortby");
		let sortDir = this.state.sortDir;
		if (sortDir === null) {
			sortDir = 'ASC';
		} else {
			sortDir = (this.state.sortDir === 'ASC') ? 'DESC' : 'ASC';
		}
		this.setState({sortItem: sortBy, sortDir: sortDir}, () => {
			this.redrawData();
		});
	};


	render() {

		let feeRows = [];
		if (this.state.fees.length > 0) {
			feeRows = this.state.fees.map((fee) =>
				<tr key={fee.symbol}>
					<td><img src={mainConfig.api.icos + fee.ico + ".png"} alt="." className="coin-ico" /><strong>{fee.symbol}</strong></td>
					<td><span>{fee.name}</span></td>
					<td>{fee.minwithdraw}</td>
					<td>{fee.wdfee}</td>
				</tr>
			);
		} else {
			feeRows = (
				<tr key="not-found">
					<td colSpan={3} style={{textAlign: "center"}}><em>Nothing found!</em></td>
				</tr>
			);
		}

		return (
			<div className="page page-fees">
				<section className="wrapper">
					<Header {...this.props}/>

					<h1 className="page-title"><FormattedMessage id="feestitle" defaultMessage="Fee for Deposit: 0% !!!" /></h1>
					<h3 className="page-title">Fee for Trading: 0.1% on all fulfilled orders!</h3>

					<div className="content">
						<div className="fin-table-hl">
							<div className="fin-table">
								<div className="fin-table-head">
									<div className="fin-table-head-left"><p>We strive to have minimal withdrawal fees.</p></div>
									<div className="fin-table-head-right">
										<input type="text" name="filter" value={this.state.searchValue}
										       className="search"
										       placeholder="Search" onChange={this.searchChange}
										       onKeyUp={this.searchChange}/>
									</div>
								</div>
								<div className="fin-table-body">
									<table>
										<thead>
										<tr>
											<th>
												<button type="button" data-sortby="symbol" onClick={this.sortChange}
												        className={
													        (this.state.sortDir != null) ?
														        'fin-order-btn ' + this.state.sortDir.toLowerCase() :
														        'fin-order-btn'
												        }>Coin
												</button>
											</th>
											<th>&nbsp;</th>
											<th>Minimal Withdrawal</th>
											<th>Fee</th>
										</tr>
										</thead>
										<tbody>
										{feeRows}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<Footer {...this.props}/>
				</section>
			</div>
		);
	}
}

export default Fees;