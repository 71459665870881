import React, { Component } from 'react';
import Link from "react-router-dom/es/Link";
import HeaderNavClient from "./funcs/HeaderNavClient";

/**
 * Head with rates
 */
class HeaderWithRates extends Component {

	pairIco = '';
	pairLabel = '';
	pairName = '';
	pairLast = '';
	pairChange = '';
	pairHigh = '';
	pairLow = '';
	pairVol = '';

	constructor(props) {
		super(props);
		this.state = {
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en'
		};
	}

	toggleAsidePanel = () => {
		this.props.onToggleAside();
	};

	render() {

		return (
			<header className="header">
				<div className="header-logo-area">
					<Link className="navbar-brand" to={'/' + this.state.lang + '/'}>
						<img src="/assets/img/logo.png" alt="Logo"/>
					</Link>
				</div>
				<div className="header-cu-rates">
					<div className="exchain-head-info">
						<div className="exchain-head-info-name">
							<img src={this.props.pairIco} alt=""/>
							<div className="ehi-name">
								<h1>{this.props.pairLabel}
									<small>{this.props.pairName}</small>
								</h1>
							</div>
							<div className="ehi-tgl-aside"><button type="button" onClick={this.props.onToggleAside}>&#9013;</button></div>
						</div>
						<div className="exchain-head-info-prices">
							<div className="ehi-top" title={this.props.pairLast}>
								{this.props.pairLast}
								<span className={(this.props.pairChange >= 0)? 'ehi-change up' : 'ehi-change down'}
								        title={this.props.pairChange}>{ this.props.pairChange }</span>
							</div>
							<div className="ehi-bottom">LAST PRICE</div>
						</div>
						<div className="exchain-head-info-prices">
							<div className="ehi-top">{this.props.pairHigh}</div>
							<div className="ehi-bottom">HIGH PRICE</div>
						</div>
						<div className="exchain-head-info-prices">
							<div className="ehi-top">{this.props.pairLow}</div>
							<div className="ehi-bottom">LOW PRICE</div>
						</div>
						<div className="exchain-head-info-prices">
							<div className="ehi-top">{this.props.pairVol}</div>
							<div className="ehi-bottom">24 H VOL</div>
						</div>
					</div>
				</div>
				<div className="header-client-nav">
					<HeaderNavClient/>
				</div>
			</header>
		);
	}
}

export default HeaderWithRates;
