import {Component} from "react";
import {mainConfig} from "../configs/main";
import Cookies from "universal-cookie";
import React from "react";

class ExchangesFilter extends Component {

	constructor(props) {
		super(props);

		let favCurrencies = [];

		const cookies = new Cookies();
		let favcurr = cookies.get("favpairs");

		if ( (typeof favcurr !== "undefined") && (favcurr != null ) && ( favcurr !== "") ) {
			favCurrencies = favcurr;
		}

		this.state = {
			btnFilterVal: "",
			searchValue : "",
			filterButtons: mainConfig.exchangeFilterItems,
			favorites: favCurrencies,
			favsOnly: false
		};
	}

	btnClick = e => {

		this.setState({searchValue: ""});


		if(e.target.value !== "FAV") {

			this.setState({favsOnly: false});
			this.props.onClick(false);

			let savedValue = this.state.btnFilterVal;

			if (savedValue !== e.target.value) {
				this.setState({btnFilterVal: e.target.value});
				this.props.setFilter(e.target.value);
			} else {
				this.setState({btnFilterVal: ""});
				this.props.setFilter("");
			}
		}

	};

	favClick = () => {

		this.setState({btnFilterVal: ""});
		this.props.setFilter("");

		if(this.state.favsOnly === false){
			this.setState({favsOnly: true});
			this.props.onClick(true);
		}else{
			this.setState({favsOnly: false});
			this.props.onClick(false);
		}
	};

	searchChange = e => {

		this.setState({searchValue: e.target.value});

		this.props.setFilter(e.target.value);
		let elms = document.getElementsByClassName("mfm-btn");
		for(let i = 0; i < elms.length; i++){
			elms[i].className="mfm-btn";
		}

	};


	render() {

		const listButtons = this.state.filterButtons.map((symbol) => {

			let btnKey = this.props.groupItem + symbol;

			if(symbol === "FAV") {
				return <button className={(this.state.favsOnly === true) ? "mfm-btn active" : "mfm-btn"}
				               key={btnKey} value="FAV" onClick={this.favClick}><img src="/assets/ic/fav.png" width="10" alt="FAV"/></button>;
			}else{
				return <button className={(this.state.btnFilterVal === symbol) ? "mfm-btn active" : "mfm-btn"}
				               key={btnKey} value={symbol} onClick={this.btnClick}>{symbol}</button>;
			}
		});

		return (
			<div className="markets-filters-all">
				<div className="markets-filter-main-search">
					<input type="text" id="marketsfilter" name="filter" value={this.state.searchValue}
					       placeholder="Search" onChange={this.searchChange} onKeyUp={this.searchChange}/>
				</div>
				<div className="markets-filter-main-markets">
					{listButtons}
				</div>
			</div>
		);
	}
}

export default ExchangesFilter;