import React, {Component} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {mainConfig} from "../configs/main";
import FinexAuth from "../lib/FinexAuth";

class AddWallet extends Component {

	constructor(props) {
		super(props);

		this.state = {
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en',
			formReady: false,
			symbol: '',
			symbolValid: 0,
			address: '',
			addressValid: 0,
			label: '',
			labelValid: 0,
			markets: [],
			errors: '',
			isLoading: false
		};
	}

	validateForm(doDisplay) {


		let valid = true;
		let errors = '';
		this.setState({errors: errors, symbolValid: 1, addressValid: 1, labelValid: 1, formReady: true});

		if (this.state.symbol.length < 1) {
			errors = 'Symbol not selected!';
			this.setState({formReady: false, symbolValid: 0});
		}

		if (this.state.address.length === 0) {
			errors = 'Wallet address too short!';
			this.setState({formReady: false, addressValid: 0});
		} else {
			if (this.state.address.length < 3) {
				errors = 'Wallet address too short!';
				this.setState({formReady: false, addressValid: 2});
			}

			if (!this.state.address.match(/[a-zA-Z]/)) {
				errors = 'Wallet address does not seems valid!';
				this.setState({formReady: false, addressValid: 2});
			}
		}

		if (this.state.label.length === 0) {
			this.setState({formReady: false, labelValid: 0});
		} else {
			if (this.state.label.length < 6) {
				errors = 'Wallet name/label too short!';
				this.setState({formReady: false, labelValid: 2});
			}
		}

		if (doDisplay) this.setState({errors: errors});

		return valid;
	}

	handleSubmit = event => {

		event.preventDefault();
		this.validateForm(true);

		let lng = this.state.lang;
		let isValid = this.validateForm(true);

		if(isValid) {

			this.setState({isLoading: true});

			const userProfile = FinexAuth.getProfile();

			const userData = {
				uid: userProfile.uid,
				au: userProfile.autk,
				address: this.state.address,
				message: this.state.message,
				symbol: this.state.symbol,
				label: this.state.label
			};

			fetch(mainConfig.api.addWallet,{
				method: "POST",
				body: JSON.stringify(userData),
				headers: {
					'Accept': 'application/json'
				},
			}).then(response => {

				response.json().then(data => {

					this.setState({isLoading: false});

					if(typeof data.error === "undefined") {
						let error = 'Something is wrong with the data send from the form [1]!';
						this.setState({formReady: false, errors: error});
					} else if (data.error === null) {
						let error = 'Something is wrong with the data send from the form [2]!';
						this.setState({formReady: false, errors: error});
					}else{

						if(data.error > 0) {
							this.setState({formReady: false, errors: data.message});
						} else {
							this.props.history.replace('/' + lng + '/wallets');
						}
					}
				})
			})
		}
	};

	handleSelectChange = event => {
		this.setState({symbol: event.target.value
		}, () => {
			this.validateForm(true);
		});
	};

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		}, () => {
			this.validateForm(true);
		});
	};

	componentWillMount() {

		let marketsObj = [];

		fetch(mainConfig.api.coins, {
			method: "GET",
			headers: {
				'Accept': 'application/json'
			},
		}).then(response => {

			response.json().then(jsondata => {

				if (typeof jsondata === "undefined") {
					let error = 'Something is wrong with the data sent from the server [1] !';
					this.setState({errors: error});
				} else if (jsondata.error !== 0) {
					let error = jsondata.message;
					this.setState({errors: error});
				} else {
					if (typeof jsondata.coins === "object") {
						jsondata.coins.forEach(function (coin) {
							marketsObj.push(coin)
						});
					}
				}
			})
		});

		this.setState({markets: marketsObj});

	}


	render() {

		let symbolOptions = [];

		if (this.state.markets && this.state.markets.length > 0) {
			symbolOptions = Object.values(this.state.markets).map(function(item) {
				return <option key={item.symbol} value={item.symbol}>({item.symbol}) {item.name}</option>
			});
		}

		return (
			<div className="page page-wallet">
				<section className="wrapper">
					<Header {...this.props}/>
					<h2 className="page-title">Apply to our coin list</h2>
					<div className="content sp-form-wrapper">
						<div className="sp-form-hl">
							<form method="post" onSubmit={this.handleSubmit}>
								<div
									className={(this.state.errors.length > 0) ? 'sp-form-err has-errors' : 'sp-form-err'}>
									{this.state.errors}
								</div>
								<div className="sp-form-row">
									<div className="sp-form-group">
										<select name="symbol" key="symbol" onChange={this.handleSelectChange} defaultValue={this.state.symbol}>
											<option value="" disabled="disabled" className="default-sel">Select currency</option>
											{symbolOptions}
										</select>
									</div>
								</div>
								<div className="sp-form-row">
									<div className="sp-form-group">
										<input type="text" name="address" id="address" key="address" value={this.state.address}
										       placeholder="Wallet address" required="required"
										       className={(this.state.addressValid === 0) ? 'inp' : ((this.state.addressValid === 1) ? 'inp valid' : 'inp invalid')}
										       onChange={this.handleChange} onBlur={this.handleChange}
										       onKeyUp={this.handleChange}/>
									</div>
								</div>
								<div className="sp-form-row">
									<div className="sp-form-group">
										<input type="text" name="label" id="label" key="label" value={this.state.label}
										       placeholder="Wallet name/label" required="required"
										       className={(this.state.labelValid === 0) ? 'inp' : ((this.state.labelValid === 1) ? 'inp valid' : 'inp invalid')}
										       onChange={this.handleChange} onBlur={this.handleChange}
										       onKeyUp={this.handleChange}/>
									</div>
								</div>
								<div className="sp-form-row">
									{(this.state.isLoading === true) ?
										<button type="button" className="sp-form-button"
										        disabled="disabled"><img src="/assets/ic/loading.gif" width="12"
										                                 alt="Loading"/></button> :
										<button type="submit" name="action" className="sp-form-button"
										        value="loginnow"
										        disabled={this.state.formReady ? false : "disabled"}>ADD NOW
										</button>
									}
								</div>
							</form>
						</div>
					</div>
					<Footer {...this.props}/>
				</section>
			</div>
		);
	}
}

export default AddWallet;