import React, {Component} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import FinexAuth from "../lib/FinexAuth";
import {mainConfig} from "../configs/main";

class Register extends Component {

	constructor(props) {
		super(props);
		this.state = {
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en',
			verifyForm: false,
			isLoading: false,
			formReady: false,
			email: '',
			emailValid: 0,
			password: '',
			password2: '',
			passwordValid: 0,
			password2Valid: 0,
			regagree: '',
			regagreeChecked: false,
			regagreeValid: 0,
			errors: '',
			accode: '',
			accodeValid: 0,
			numtries: 10 //TODO: implement me pls.
		};
	}

	validateForm(doDisplay) {


		let valid = true;
		let errors = '';
		this.setState({errors: errors, emailValid: 1, passwordValid: 1, password2Valid: 1, regagreeValid: 1, formReady: true});

		if (this.state.email.length === 0) {
			errors = 'Email address too short!';
			this.setState({formReady: false, emailValid: 0});
		} else {
			if (this.state.email.length < 3) {
				errors = 'Email address too short!';
				this.setState({formReady: false, emailValid: 2});
			}

			if (!this.state.email.match(/@/) || !this.state.email.match(/\./)) {
				errors = 'Email address does not seems valid!';
				this.setState({formReady: false, emailValid: 2});
			}
		}

		if (this.state.password.length === 0) {
			this.setState({formReady: false, passwordValid: 0});
		} else {
			if (this.state.password.length < 6) {
				errors = 'Password too short!';
				this.setState({formReady: false, passwordValid: 2});
			}
		}


		if (this.state.password2.length === 0) {
			this.setState({formReady: false, password2Valid: 0});
		} else {
			if (this.state.password2.length < 6) {
				errors = 'Second password is too short!';
				this.setState({formReady: false, password2Valid: 2});
			}
			if (this.state.password !== this.state.password2) {
				errors = 'Passwords are not the same!';
				this.setState({formReady: false, password2Valid: 2});
			}
		}

		if(this.state.regagreeChecked === false){
			errors = 'You have to agree with our terms of service!';
			this.setState({formReady: false, regagreeValid: 2});
		}

		if (doDisplay) this.setState({errors: errors});

		return valid;
	}

	handleSubmit = event => {

		event.preventDefault();

		let isValid = this.validateForm(true);

		if(isValid) {

			this.setState({isLoading: true});

			const userData = {
				email: this.state.email,
				password: this.state.password
			};

			fetch(mainConfig.api.register,{
				method: "POST",
				body: JSON.stringify(userData),
				headers: {
					'Accept': 'application/json'
				},
			}).then(response => {
				response.json().then(data => {

					this.setState({isLoading: false});

					if(typeof data.error === "undefined") {
						let error = 'Something is wrong with the data send from the form [1]!';
						this.setState({formReady: false, errors: error});
					} else if (data.error === null) {
						let error = 'Something is wrong with the data send from the form [2]!';
						this.setState({formReady: false, errors: error});
					}else{

						if(data.error > 0) {
							this.setState({formReady: false, errors: data.message});
						} else {
							if( typeof data.profile.autk === "undefined") {
								let error = 'No data received from the service! [1]';
								this.setState({formReady: false, errors: error});
							} else if( data.profile.autk.length < 3) {
								let error = 'No data received from the service! [2]';
								this.setState({formReady: false, errors: error});
							} else {
								FinexAuth.saveProfile(data.profile);
								this.setState({verifyForm: true})
							}
						}
					}
				})
			})
		}
	};

	validateActivationForm(doDisplay) {

		let valid = true;
		let errors = '';
		this.setState({errors: errors, accodeValid: 1, formReady: true});

		if (this.state.accode.length < 6) {
			errors = 'Activation code too short!';
			this.setState({formReady: false, accodeValid: 0});
		}

		if (doDisplay) this.setState({errors: errors});

		return valid;
	}

	handleActivationSubmit = event => {

		event.preventDefault();

		let lng = this.state.lang;
		let isValid = this.validateActivationForm(true);

		if(isValid) {

			const userProfile = FinexAuth.getProfile();
			const authToken = userProfile.autk;

			if( this.state.accode !== userProfile.accode) {
				let error = 'Wrong activation code!';
				this.setState({formReady: false, errors: error});
			} else {

				this.setState({isLoading: true});

				fetch(mainConfig.api.regmailconfirm,{
					method: "POST",
					body: JSON.stringify({uid: userProfile.uid, autk: authToken, code: this.state.accode}),
					headers: { 'Accept': 'application/json' },
				}).then(response => {
					response.json().then( data => {

						if(typeof data.error === "undefined") {

							let error = 'Something went wrong, please try again!';
							this.setState({formReady: false, isLoading: false, errors: error});

						} else {
							if(data.error > 0) {
								this.setState({formReady: false, isLoading: false, errors: data.message});
							}else{
								this.setState({isLoading: false}, () => {
									this.props.history.replace('/' + lng + '/myaccount');
								});
							}
						}
					})
				});
			}
		}

	};

	handleChange = event => {

		if(event.target.id === "regagree") {
			this.setState({regagreeChecked: event.target.checked}, () => {
				this.validateForm(true);
			});
		}else{
			this.setState({[event.target.id]: event.target.value}, () => {
				this.validateForm(true);
			});
		}
	};

	handleActivationChange = event => {

		this.setState({[event.target.id]: event.target.value.toUpperCase()}, () => {
			this.validateActivationForm(true);
		});
	};

	componentWillMount() {

	}


	render() {

		let lng = this.state.lang;

		if(this.state.verifyForm === true) {

			return (
				<div className="page page-register">
					<section className="wrapper">
						<Header {...this.props}/>
						<h2 className="page-title">Register for free</h2>
						<div className="content sp-form-wrapper">

							<div className="sp-form-hl">
								<h4 className="sp-form-registration-title">Enter activation code</h4>
								<form method="post" onSubmit={this.handleActivationSubmit} autoComplete="off">
									<div
										className={(this.state.errors.length > 0) ? 'sp-form-err has-errors' : 'sp-form-err'}>
										{this.state.errors}
									</div>
									<div className="sp-form-row">
										<div className="sp-form-group">
											<input type="text" name="accode" id="accode" value={this.state.accode}
											       placeholder="ABC123" required="required"
											       className={(this.state.accodeValid === 0) ? 'inp' : ((this.state.accodeValid === 1) ? 'inp valid' : 'inp invalid')}
											       onChange={this.handleActivationChange} onBlur={this.handleActivationChange}
											       onKeyUp={this.handleActivationChange}/>
											<div className="sp-form-inp-note">
												<i className="ic-info" /> Enter the activation code, which you will receive by email.
											</div>
										</div>
									</div>
									<div className="sp-form-row">
										{(this.state.isLoading === true) ?
											<button type="button" className="sp-form-button"
											        disabled="disabled"><img src="/assets/ic/loading.gif" width="12"
											                                 alt="Loading" /></button> :
											<button type="submit" name="action" className="sp-form-button"
											        value="registernow">ACTIVATE</button>
										}
									</div>
								</form>
							</div>
							<div className="sp-form-alternatives">
								Already have an account? <Link to={'/' + lng + '/login'}>Login</Link>
							</div>
						</div>
						<Footer {...this.props}/>
					</section>
				</div>
			);

		}else {

			return (
				<div className="page page-register">
					<section className="wrapper">
						<Header {...this.props}/>
						<h2 className="page-title">Register for free</h2>
						<div className="content sp-form-wrapper">
							<div className="sp-form-hl">
								<h4 className="sp-form-registration-title">Trade on your own risk</h4>
								<form method="post" onSubmit={this.handleSubmit}>
									<div
										className={(this.state.errors.length > 0) ? 'sp-form-err has-errors' : 'sp-form-err'}>
										{this.state.errors}
									</div>
									<div className="sp-form-row">
										<div className="sp-form-group">
											<input type="email" name="email" id="email" value={this.state.email}
											       placeholder="Email Address" required="required"
											       className={(this.state.emailValid === 0) ? 'inp' : ((this.state.emailValid === 1) ? 'inp valid' : 'inp invalid')}
											       onChange={this.handleChange} onBlur={this.handleChange}
											       onKeyUp={this.handleChange}/>
											<div className="sp-form-inp-note">
												<i className="ic-info" /> Please provide a valid email address. You will
												need it for important operations.
											</div>
										</div>
									</div>
									<div className="sp-form-row">
										<div className="sp-form-group">
											<input type="password" name="password" id="password"
											       value={this.state.password}
											       placeholder="Password" required="required"
											       className={(this.state.passwordValid === 0) ? 'inp' : ((this.state.passwordValid === 1) ? 'inp valid' : 'inp invalid')}
											       onChange={this.handleChange} onBlur={this.handleChange}
											       onKeyUp={this.handleChange}/>
										</div>
									</div>
									<div className="sp-form-row">
										<div className="sp-form-group">
											<input type="password" name="password2" id="password2"
											       value={this.state.password2}
											       placeholder="Confirm Password" required="required"
											       className={(this.state.password2Valid === 0) ? 'inp' : ((this.state.password2Valid === 1) ? 'inp valid' : 'inp invalid')}
											       onChange={this.handleChange} onBlur={this.handleChange}
											       onKeyUp={this.handleChange}/>
										</div>
									</div>
									<div className="sp-form-row">
										<div className="sp-form-check-group">
											<input type="checkbox" name="regagree" id="regagree" value="1"
											       required="required"
											       checked={this.state.regagreeChecked}
											       className={(this.state.regagreeValid === 0) ? 'inp' : ((this.state.regagreeValid === 1) ? 'inp valid' : 'inp invalid')}
											       onChange={this.handleChange} onClick={this.handleChange}
											       onKeyUp={this.handleChange}/>
											I agree to FINEXLY <Link to={'/' + lng + '/terms'} className="sp-lost-pass-lnk">Terms of
											Service</Link>
										</div>
									</div>
									<div className="sp-form-row">
										{(this.state.isLoading === true) ?
											<button type="button" className="sp-form-button"
											        disabled="disabled"><img src="/assets/ic/loading.gif" width="12"
											                                 alt="Loading" /></button> :
											<button type="submit" name="action" className="sp-form-button"
											        value="registernow"
											        disabled={this.state.formReady ? false : "disabled"}>REGISTER
											</button>
										}
									</div>
								</form>
							</div>
							<div className="sp-form-alternatives">
								Already have an account? <Link to={'/' + lng + '/login'}>Login</Link>
							</div>
						</div>
						<Footer {...this.props}/>
					</section>
				</div>
			);
		}
	}
}

export default Register;