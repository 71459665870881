import React, {Component} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {mainConfig} from "../configs/main";

class ApplyToCoinList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			topDomainDiff: 0,
			topDomainLocation: '',
			formReady: false,
			email: '',
			emailValid: 0,
			message: '',
			messageValid: 0,
			errors: '',
			isLoading: false,
			numtries: 10 //TODO: implement me pls.
		};
	}

	validateForm(doDisplay) {
		let valid = true;
		let errors = '';
		this.setState({errors: errors, emailValid: 1, messageValid: 1, formReady: true});

		if (this.state.email.length === 0) {
			errors = 'Email address too short!';
			this.setState({formReady: false, emailValid: 0});
		} else {
			if (this.state.email.length < 3) {
				errors = 'Email address too short!';
				this.setState({formReady: false, emailValid: 2});
			}

			if (!this.state.email.match(/@/) || !this.state.email.match(/\./)) {
				errors = 'Email address does not seems valid!';
				this.setState({formReady: false, emailValid: 2});
			}
		}

		if (this.state.message.length === 0) {
			this.setState({formReady: false, messageValid: 0});
		} else {
			if (this.state.message.length < 6) {
				errors = 'Message too short!';
				this.setState({formReady: false, messageValid: 2});
			}
		}

		if (doDisplay) this.setState({errors: errors});

		return valid;
	}

	handleSubmit = event => {
		event.preventDefault();
		this.validateForm(true);

		let isValid = this.validateForm(true);

		if(isValid) {

			this.setState({isLoading: true});

			const userData = {
				email: this.state.email,
				message: this.state.message
			};

			fetch(mainConfig.api.login,{
				method: "POST",
				body: JSON.stringify(userData),
				headers: {
					'Accept': 'application/json'
				},
			}).then(response => {

				response.json().then(data => {

					this.setState({isLoading: false});

					let error = (typeof data.message !== "undefined") ? data.message : '';

					if(typeof data.state === "undefined") {
						let error = 'Something is wrong with the data send from the form [1]!';
						this.setState({formReady: false, errors: error});
					} else if (data.state === null) {
						let error = 'Something is wrong with the data send from the form [2]!';
						this.setState({formReady: false, errors: error});
					}else{

						if(data.state > 0) {
							if(data.relatedto === 'message') {
								this.setState({formReady: false, errors:error, messageValid: 2});
							} else if (data.relatedto === 'email') {
								this.setState({formReady: false, errors: error, emailValid: 2});
							} else {
								this.setState({formReady: false, errors: error});
							}
						} else {
							if( typeof data.profile.autk === "undefined") {
								let error = 'No data received from the service!';
								this.setState({formReady: false, errors: error});
							} else if( data.profile.autk.length < 3) {
								let error = 'No data received from the service!';
								this.setState({formReady: false, errors: error});
							} else {
								this.setState({verifyForm: true});
								this.props.history.replace('/');
							}
						}
					}
				})
			})
		}
	};

	handleChange = event => {

		this.setState({
			[event.target.id]: event.target.value
		}, () => {
			this.validateForm(true);
		});
	};

	componentWillMount() {


	}


	render() {
		return (
			<div className="page page-login">
				<section className="wrapper">
					<Header {...this.props}/>
					<h2 className="page-title">Apply to our coin list</h2>
					<div className="content sp-form-wrapper">

						<div className="sp-form-hl">
							<div className="sp-form-check-domain">
								<p>Please provide detailed information about your<br/> crypto coin!</p>
							</div>
							<form method="post" onSubmit={this.handleSubmit}>
								<div
									className={(this.state.errors.length > 0) ? 'sp-form-err has-errors' : 'sp-form-err'}>
									{this.state.errors}
								</div>
								<div className="sp-form-row">
									<div className="sp-form-group">
										<input type="email" name="email" id="email" value={this.state.email}
										       placeholder="Email Address" required="required"
										       className={(this.state.emailValid === 0) ? 'inp' : ((this.state.emailValid === 1) ? 'inp valid' : 'inp invalid')}
										       onChange={this.handleChange} onBlur={this.handleChange}
										       onKeyUp={this.handleChange}/>
									</div>
								</div>
								<div className="sp-form-row">
									<div className="sp-form-group">
										<textarea rows={6} cols={50} name="message" id="message" value={this.state.message}
										       placeholder="Describe your coin" required="required"
										       className={(this.state.messageValid === 0) ? 'inp' : ((this.state.messageValid === 1) ? 'inp valid' : 'inp invalid')}
										       onChange={this.handleChange} onBlur={this.handleChange}
										       onKeyUp={this.handleChange}/>
									</div>
								</div>
								<div className="sp-form-row">
									{(this.state.isLoading === true) ?
										<button type="button" className="sp-form-button"
										        disabled="disabled"><img src="/assets/ic/loading.gif" width="12"
										                                 alt="Loading" /></button> :
										<button type="submit" name="action" className="sp-form-button"
										        value="loginnow"
										        disabled={this.state.formReady ? false : "disabled"}>APPLY NOW
										</button>
									}
								</div>
							</form>
						</div>
					</div>
					<Footer {...this.props}/>
				</section>
			</div>
		);
	}
}

export default ApplyToCoinList;