import React, {Component} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";

class MyAccount extends Component {

	constructor(props) {
		super(props);

		this.state = {
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en'
		};
	}

	render() {

		return (
			<div className="page page-myaccount">
				<section className="wrapper">
					<Header {...this.props}/>
					<h2 className="page-title">My Account</h2>
					<div className="content">
						<div className="account-tabs">

						</div>
					</div>
					<Footer {...this.props}/>
				</section>
			</div>
		);
	}
}

export default MyAccount;