export const mainConfig = {
	decimals: 8,
	api: {
		icos: 'https://finapi.cincev.com/static/cuico/',
		pairs: 'https://finapi.cincev.com/v1/pairs/extended',
		fees: 'https://finapi.cincev.com/v1/fees/extended',
		assets: 'https://finapi.cincev.com/v1/assets/extended',

		pair: 'https://finuapi.cincev.com/pair/',
		pairws: 'wss://finuapi.cincev.com:5000/',
		content: 'https://finuapi.cincev.com/content/',
		newsList: 'https://finuapi.cincev.com/news-recent/',
		newsArticle: 'https://finuapi.cincev.com/news-article/',
		register: 'https://finuapi.cincev.com/register/',
		login: 'https://finuapi.cincev.com/login/',
		loginga: 'https://finuapi.cincev.com/loginga/',
		lostpassRequest: 'https://finuapi.cincev.com/lostpassreq/',
		lostpassTryCode: 'https://finuapi.cincev.com/lostpasscode/',
		lostpassSetNewPass: 'https://finuapi.cincev.com/lostpassnew/',
		auth: 'https://finuapi.cincev.com/syncauth/',
		logout: 'https://finuapi.cincev.com/logout/',
		wallets: 'https://finuapi.cincev.com/wallets/',
		regmailconfirm: 'https://finuapi.cincev.com/regmailconfirm/',
		setnewpass: 'https://finuapi.cincev.com/setnewpass/',
		getsgapicture: 'https://finuapi.cincev.com/getsgapicture/',
		gaactivate: 'https://finuapi.cincev.com/gaactivate/',
		gadeactivate: 'https://finuapi.cincev.com/gadeactivate/',
		addWallet: 'https://finuapi.cincev.com/walletsadd/',

		neworder: 'https://finuapi.cincev.com/neworder/',
	},
	exchangeFilterItems: ["FAV","BTC","USDT","LTC","DOGE"]

};