import React, {Component} from 'react';
import PropTypes from 'prop-types'
import '../assets/css/notifications.css'

/**
 * Notification block from top
 */
class Notification extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			notifCounterId: null,
			timeout: 5,
			msg: "",
			redirectTo: null,
			blkClass: "notification-blk",
			btns: []
		};
	}

	componentDidMount() {

		let setProps = {};
		if (typeof this.props.timeout !== "undefined") setProps.timeout = Number(this.props.timeout);
		if (typeof this.props.msg !== "undefined") setProps.msg = this.props.msg;
		if (typeof this.props.redirectTo !== "undefined") setProps.redirectTo = this.props.redirectTo;
		if (typeof this.props.type !== "undefined") setProps.blkClass = this.state.blkClass + ' ' + this.props.type;

		if (Object.keys(setProps).length > 0) {
			this.setState(setProps);
		}

		this.setState({
			notifCounterId: setInterval(() => {
				this.decreaseNotification()
			}, 1000)
		});
	}

	componentWillUnmount() {
		clearInterval(this.state.notifCounterId);
	}

	closeNotification = () => {
		this.setState({blkClass: "notification-blk hide-me"})
		if (this.state.redirectTo !== null && this.state.redirectTo.length > 3) {
			window.location.href = this.state.redirectTo;
		}
	};

	decreaseNotification() {
		if (this.state.timeout < 2) {
			this.setState({blkClass: "notification-blk hide-me"}, () => {
				clearInterval(this.state.notifCounterId);

				if (this.state.redirectTo !== null && this.state.redirectTo.length > 3) {
					window.location.href = this.state.redirectTo;
				}

			})
		} else {
			this.setState({timeout: (this.state.timeout - 1)});
		}
	}

	render() {

		return (
			<div key="notification-blk" className={this.state.blkClass}>
				<div className="notification-head">
					<button onClick={this.closeNotification}>&#10005;</button>
				</div>
				<div className="notification-body">
					<p>{this.state.msg}</p>
				</div>
				<div className="notification-footer">
				</div>
			</div>
		);
	}
}

export const notificationType = {
	info: 'info',
	error: 'error',
	success: 'success'
};

Notification.propTypes = {
	msg: PropTypes.string,
	timeout: PropTypes.string,
	redirectTo: PropTypes.string,
	type: PropTypes.oneOf(Object.keys(notificationType))
};

export default Notification;

