import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';
import './Responsive.css';
import './ThemeDark.css';
import packageJson from '../package.json';
import Home from "./pages/Home"
import Pair from "./pages/Pair";
import PageNotFound from "./pages/PageNotFound";
import Login from "./pages/Login";
import FinexError from "./components/FinexError";
import Register from "./pages/Register";
import TermsOfUse from "./pages/TermsOfUse";
import Fees from "./pages/Fees";
import LostPassword from "./pages/LostPassword";
import FinexAuth from "./lib/FinexAuth";
import {Redirect} from "react-router";
import NewsListing from "./pages/NewsListing";
import NewsArticle from "./pages/NewsArticle";
import Coins from "./pages/Coins";
import ApplyToCoinList from "./pages/ApplyToCoinList";
import MyAccount from "./pages/MyAccount";
import AccountSecurity from "./pages/AccountSecurity";
import Security from "./pages/Security";
import Wallets from "./pages/Wallets";
import AddWallet from "./pages/AddWallet";
import Support from "./pages/Support";
import ApiDocs from "./pages/ApiDocs";

import {addLocaleData, IntlProvider} from 'react-intl';
import en from 'react-intl/locale-data/en';
import bg from 'react-intl/locale-data/bg';

import messages_en from "./translations/en.json";
import messages_bg from "./translations/bg.json";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const messages = {
	'en': messages_en,
	'bg': messages_bg,
};

addLocaleData([...en, ...bg]);

function getLang(props) {
	return (typeof props.match.params.lng === "undefined") ? 'en' : props.match.params.lng
}

let finexAuth = FinexAuth;

const PrivateRoute = ({component: Component, ...rest}) => {

	if(finexAuth.isAuthenticated() === true){
		return (
			<Route {...rest} render={(props) => (
				<IntlProvider locale={getLang(props)} messages={messages[getLang(props)]} lng={getLang(props)}>
					<Component {...props} lng={getLang(props)} />
				</IntlProvider>
			)} />
		)
	}else {
		return (
			<Route {...rest} render={(props) => (
				<Redirect to={'/' + getLang(props) + '/login'} />
			)} />
		)
	}
};


/**
 * Main App system
 */
class App extends Component {

	componentDidMount() {
		let fc = document.getElementsByClassName('footer-copyrights');
		if (typeof fc !== "undefined") if (typeof fc[0] !== "undefined") fc[0].setAttribute('title', packageJson.version);
	}

	render() {

		return (
			<FinexError>
				<Router>
					<Switch>
						<PrivateRoute exact path='/:lng?/myaccount' component={MyAccount} />
						<PrivateRoute exact path='/:lng?/mysecurity' component={AccountSecurity} />
						<PrivateRoute exact path='/:lng?/security' component={Security} />
						<PrivateRoute exact path='/:lng?/wallets' component={Wallets} />
						<PrivateRoute exact path='/:lng?/addwallet' component={AddWallet} />

						<Route exact path='/:lng?/pair/:pair' render={(props) => (
							<IntlProvider locale={getLang(props)} messages={messages[getLang(props)]}>
								<Pair {...props} lng={getLang(props)} auth={finexAuth}/>
							</IntlProvider>
						)}/>

						<Route exact path='/:lng?/login' render={(props) => (
							<IntlProvider locale={getLang(props)} messages={messages[getLang(props)]}>
								<Login {...props} lng={getLang(props)}/>
							</IntlProvider>
						)}/>

						<Route exact path='/:lng?/register' render={(props) => (
							<IntlProvider locale={getLang(props)} messages={messages[getLang(props)]}>
								<Register {...props} lng={getLang(props)}/>
							</IntlProvider>
						)}/>

						<Route exact path='/:lng?/lostpass' render={(props) => (
							<IntlProvider locale={getLang(props)} messages={messages[getLang(props)]}>
								<LostPassword {...props} lng={getLang(props)}/>
							</IntlProvider>
						)}/>

						<Route exact path='/:lng?/fees' render={(props) => (
							<IntlProvider locale={getLang(props)} messages={messages[getLang(props)]}>
								<Fees {...props} lng={getLang(props)}/>
							</IntlProvider>
						)}/>

						<Route exact path='/:lng?/apply-to-coin-list' render={(props) => (
							<IntlProvider locale={getLang(props)} messages={messages[getLang(props)]}>
								<ApplyToCoinList {...props} lng={getLang(props)}/>
							</IntlProvider>
						)}/>

						<Route exact path='/:lng?/news/:article' render={(props) => (
							<IntlProvider locale={getLang(props)} messages={messages[getLang(props)]}>
								<NewsArticle {...props} lng={getLang(props)}/>
							</IntlProvider>
						)}/>

						<Route exact path='/:lng?/news' render={(props) => (
							<IntlProvider locale={getLang(props)} messages={messages[getLang(props)]}>
								<NewsListing {...props} lng={getLang(props)}/>
							</IntlProvider>
						)}/>

						<Route exact path='/:lng?/coins' render={(props) => (
							<IntlProvider locale={getLang(props)} messages={messages[getLang(props)]}>
								<Coins {...props} lng={getLang(props)}/>
							</IntlProvider>
						)}/>

						<Route exact path='/:lng?/support' render={(props) => (
							<IntlProvider locale={getLang(props)} messages={messages[getLang(props)]}>
								<Support {...props} lng={getLang(props)}/>
							</IntlProvider>
						)}/>

						<Route exact path='/:lng?/apidocs' render={(props) => (
							<IntlProvider locale={getLang(props)} messages={messages[getLang(props)]}>
								<ApiDocs {...props} lng={getLang(props)}/>
							</IntlProvider>
						)}/>

						<Route exact path='/:lng?/privacypolicy' render={(props) => (
							<IntlProvider locale={getLang(props)} messages={messages[getLang(props)]}>
								<PrivacyPolicy {...props} lng={getLang(props)}/>
							</IntlProvider>
						)}/>

						<Route exact path='/:lng?/terms' render={(props) => (
							<IntlProvider locale={getLang(props)} messages={messages[getLang(props)]}>
								<TermsOfUse {...props} lng={getLang(props)}/>
							</IntlProvider>
						)}/>

						<Route exact path='/:lng?/termofuse' render={(props) => (
							<IntlProvider locale={getLang(props)} messages={messages[getLang(props)]}>
								<TermsOfUse {...props} lng={getLang(props)}/>
							</IntlProvider>
						)}/>

						{/* HOME PAGE */}
						<Route exact path='/' render={(props) => (
							<IntlProvider locale="en" messages={messages['en']}>
								<Home {...props} lng="en"/>
							</IntlProvider>
						)}/>
						<Route exact path='/en' render={(props) => (
							<IntlProvider locale="en" messages={messages['en']}>
								<Home {...props} lng="en"/>
							</IntlProvider>
						)}/>
						<Route exact path='/fr' render={(props) => (
							<IntlProvider locale="fr" messages={messages['fr']}>
								<Home {...props} lng="fr"/>
							</IntlProvider>
						)}/>
						{/* END OF HOME PAGE */}

						<Route path='*' exact={true} component={PageNotFound}/>

					</Switch>
				</Router>
			</FinexError>
		);
	}
}

export default App;
