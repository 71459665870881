import React, { Component } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import {mainConfig} from "../configs/main";
import HTMLReactParser from 'html-react-parser';

/**
 * News article page
 */
class NewsArticle extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			lang: (typeof props.lng !== "undefined") ? props.lng : 'en',
			content: {
				title: '',
				content: '<span>&nbsp;</span>'
			},
			errors: '',
		};
	}

	componentWillMount() {

		this.setState({errors: ''});

		let nid = 2;
		if(typeof this.props.match.params.article !== "undefined") {
			let tmpNid = Number(this.props.match.params.article);
			if(!isNaN(tmpNid) && tmpNid > 0) nid = tmpNid;
		}

		fetch(mainConfig.api.newsArticle + "/" + nid, {
			method: "GET",
			headers: {
				'Accept': 'application/json'
			},
		}).then(response => {

			response.json().then(jsondata => {

				if (typeof jsondata === "undefined") {

					let error = 'Something is wrong with the data send from the server [1] !';
					this.setState({errors: error});

				} else if (Number(jsondata.error) !== 0) {

					let error = jsondata.message;
					this.setState({errors: error});

				} else if (jsondata.article === null) {

					let error = 'Something is wrong with the data send from the server [2]!';
					this.setState({errors: error});

				} else {

					if (typeof jsondata.article === "object") {
						this.setState({content: jsondata.article});
					}
				}
			})
		})
	}

	render() {

		let lng = this.state.lang;

		return (
			<div className="page page-news-article">
				<section className="wrapper">
					<Header {...this.props}/>
					<div className="content">
						<h1>{this.state.content.title} <small>{this.state.content.articledate}</small></h1>
						<div className="article-content">{HTMLReactParser(this.state.content.content)}</div>
						<Link to={'/' + lng + '/news'} className="back-btn">&#9666;Back</Link>
					</div>
					<Footer {...this.props}/>
				</section>
			</div>
		);
	}
}

export default NewsArticle;