import {mainConfig} from "../configs/main";

class FAuth {

	constructor() {

		//this.init = this.init.bind(this);
		this._checkAuth = this._checkAuth.bind(this);
		this.getProfile = this.getProfile.bind(this);
		this.isAuthenticated = this.isAuthenticated.bind(this);
		this.signOut = this.signOut.bind(this);
	}

	_checkAuth() {

		// console.log('_checkAuth called');

		const _profile = this.getProfile();

		fetch(mainConfig.api.auth,{
			method: "POST",
			body: JSON.stringify( {uid: _profile.uid, autk: _profile.autk} ),
			headers: {
				'Accept': 'application/json'
			},
		}).then(response => {
			response.json().then(data => {

				if(typeof data.error === "undefined") {
					this.signOut()
				} else if (data.error === null) {
					this.signOut()
				}else{
					if(data.error > 0) {
						this.signOut()
					} else {
						let profile = this.getProfile();
						profile.expiresAt = (10 * 60000) + new Date().getTime();
						sessionStorage.setItem('u', JSON.stringify(profile));
						return true;
					}
				}
				return false;
			})
		})
	}

	saveProfile(profile) {
		sessionStorage.setItem('u', JSON.stringify(profile));
	}

	getProfile() {

		let uProfil = { uid: 0, email: '' , state: 0, s2a : 0, accode: null, autk: '', expiresAt: (10 * 60000) + new Date().getTime() };
		let _profile = {};

		const _storProfil = sessionStorage.getItem('u');
		if( (_storProfil !== null) && (_storProfil.length > 0) ) {
			if (typeof _storProfil === "string") {
				_profile = JSON.parse(_storProfil);
			} else {
				_profile = _storProfil;
			}
		}

		if(typeof _profile.uid !== "undefined") uProfil.uid = Number(_profile.uid);
		if(typeof _profile.email !== "undefined") uProfil.email = _profile.email;
		if(typeof _profile.state !== "undefined") uProfil.state = Number(_profile.state);
		if(typeof _profile.s2a !== "undefined") uProfil.s2a = Number(_profile.s2a);
		if(typeof _profile.accode !== "undefined") uProfil.accode = _profile.accode;
		if(typeof _profile.expiresAt !== "undefined") uProfil.expiresAt = _profile.expiresAt;
		if(typeof _profile.autk !== "undefined") uProfil.autk = _profile.autk;

		return uProfil;
	}

	isAuthenticated() {

		//console.log('isAuthenticated called');

		const profile = this.getProfile();

		if(profile.uid < 1) {
			return false;
		}else {
			if ((new Date().getTime() > profile.expiresAt)) {
				return this._checkAuth();
			} else {
				return true;
			}
		}
	}

	init() {
		const profile = { uid: 0, email: '' , state: 0, s2a : 0, accode: null, autk: '', expiresAt: 0 };
		sessionStorage.setItem('u', JSON.stringify(profile));
	}

	signOut() {
		const profile = { uid: 0, email: '' , state: 0, s2a : 0, accode: null, autk: '', expiresAt: 0 };
		sessionStorage.setItem('u', JSON.stringify(profile));
	}
}

const FinexAuth = new FAuth();

export default FinexAuth;